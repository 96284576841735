import { ReactNode, useState } from "react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { SearchTextInput } from "@/components/common/elements/input/SearchTextInput";
import { classNames } from "@/utils";
import Modal from "./Modal";

type Item = { id: string; name: string };

const COMMON_ITEM_CLASS = "text-left hover:font-bold hover:text-ocean cursor-pointer";

const SelectModal = ({
  itemName,
  items,
  itemDisplay,
  onClose,
  onSubmit,
}: {
  itemName: string;
  items?: Item[];
  itemDisplay: (i: Item) => ReactNode;
  onClose: () => void;
  onSubmit?: () => void;
}) => {
  const [selectSearch, setSelectSearch] = useState("");
  const filteredItems =
    items && items.filter(i => !selectSearch || i.name.toLowerCase().includes(selectSearch.toLowerCase()));

  return (
    <Modal
      title={`Select ${itemName}`}
      onClose={onClose}
      onSubmit={onSubmit}
      containerStyle={{ height: "75%", width: "50rem", maxWidth: "75%" }}
    >
      <div className="w-full h-full flex flex-col gap-4">
        <SearchTextInput value={selectSearch} setValue={setSelectSearch} />
        <div className="py-2 w-full h-0 grow flex flex-col gap-1 overflow-y-auto border-y border-gray-200">
          {filteredItems ? filteredItems.map(itemDisplay) : <p>Loading...</p>}
        </div>
      </div>
    </Modal>
  );
};

export const SingleSelectModal = ({
  itemName,
  items,
  selectItem,
  onClose,
}: {
  itemName: string;
  items?: Item[];
  selectItem: (id: string) => void;
  onClose: () => void;
}) => {
  return (
    <SelectModal
      itemName={itemName}
      items={items}
      onClose={onClose}
      itemDisplay={i => (
        <button
          onClick={() => {
            onClose();
            selectItem(i.id);
          }}
          className={COMMON_ITEM_CLASS}
          key={i.id}
        >
          {i.name}
        </button>
      )}
    />
  );
};

export const CheckboxIcon = ({ isEnabled, className = "w-4 h-4" }: { isEnabled?: boolean; className?: string }) => (
  <div
    className={classNames(
      isEnabled ? "bg-ocean text-white" : "bg-white text-ocean",
      "p-px shrink-0 border border-ocean rounded-md grid place-items-center",
      className,
    )}
  >
    {isEnabled && <CheckIcon className="w-full h-full" strokeWidth={3} />}
  </div>
);

export const MultiSelectModal = ({
  itemName,
  items,
  selectItems,
  onClose,
}: {
  items?: Item[];
  selectItems: (ids: string[]) => void;
  itemName: string;
  onClose: () => void;
}) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const toggleItem = (toggleId: string, isSelected: boolean) => {
    isSelected ? setSelectedIds(selectedIds.filter(id => id !== toggleId)) : setSelectedIds([...selectedIds, toggleId]);
  };

  return (
    <SelectModal
      itemName={itemName}
      items={items}
      onSubmit={() => {
        onClose();
        selectItems(selectedIds);
      }}
      onClose={onClose}
      itemDisplay={i => {
        const isSelected = selectedIds.includes(i.id);
        return (
          <button
            onClick={() => toggleItem(i.id, isSelected)}
            className={classNames(COMMON_ITEM_CLASS, "flex flex-row gap-4 items-center", isSelected ? "font-bold" : "")}
            key={i.id}
          >
            <CheckboxIcon isEnabled={isSelected} />
            <span>{i.name}</span>
          </button>
        );
      }}
    />
  );
};
