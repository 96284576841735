import { last } from "lodash";
import { DateTime } from "luxon";
import { mapExists, getSequence } from "@parallel/vertex/util/collection.util";

/**
 * get a date relative to now
 * @param delta - amount of units to move relatively (use negative for past dates)
 * @param unit - unit of time to move relatively by
 * @returns relative date
 */
export const relativeDate = (
  delta: number,
  unit: "seconds" | "minutes" | "hours" | "days" | "months",
  from: Date = new Date(),
): Date => {
  const d = new Date(from.getTime());
  switch (unit) {
    case "seconds":
      d.setSeconds(d.getSeconds() + delta);
      break;
    case "minutes":
      d.setMinutes(d.getMinutes() + delta);
      break;
    case "hours":
      d.setHours(d.getHours() + delta);
      break;
    case "days":
      d.setDate(d.getDate() + delta);
      break;
    case "months":
      d.setMonth(d.getMonth() + delta);
      break;
  }
  return d;
};

export const relativeDateTime = (
  delta: number,
  unit: "minutes" | "hours" | "days" | "months",
  from: Date = new Date(),
) => DateTime.fromJSDate(relativeDate(delta, unit, from));

// will be positive if d1 is before d2
export const timeDelta = (d1: Date, d2: Date, unit: "millis" | "minutes" | "hours" | "days"): number => {
  const deltaMs = d2.getTime() - d1.getTime();
  switch (unit) {
    case "millis":
      return deltaMs;
    case "minutes":
      return Math.round(deltaMs / 1000 / 60);
    case "hours":
      return Math.round(deltaMs / 1000 / 60 / 60);
    case "days":
      return Math.round(deltaMs / 1000 / 60 / 60 / 24);
  }
};

export const shortTimeZone = (d: Date, dayOverride?: Date) => {
  if (dayOverride) {
    d.setDate(dayOverride.getDate());
    d.setMonth(dayOverride.getMonth());
    d.setFullYear(dayOverride.getFullYear());
  }
  const zonedTimeString = d.toLocaleTimeString("en-us", { timeZoneName: "short" });
  return last(zonedTimeString.split(" "));
};

export const dateDiffInSeconds = (
  start?: Date | null | undefined,
  end?: Date | null | undefined,
): number | undefined => {
  if (!start || !end) return undefined;
  const differenceInMs = end.getTime() - start.getTime();
  return differenceInMs / 1000.0;
};

type Time = {
  hour: number;
  minute: number;
  second: number;
  milli: number;
};

const toTime = (d: Date): Time => ({
  hour: d.getHours(),
  minute: d.getMinutes(),
  second: d.getSeconds(),
  milli: d.getMilliseconds(),
});

export const applyTimeToDate = (date: Date, { hour = 0, minute = 0, second = 0, milli = 0 }: Partial<Time>) => {
  const then = new Date(date.getTime());

  then.setHours(hour);
  then.setMinutes(minute);
  then.setSeconds(second);
  then.setMilliseconds(milli);

  return then;
};

export const currTimeOnDate = (d: Date) => applyTimeToDate(d, toTime(new Date()));

export const startOfDay = (d: Date = new Date()) => applyTimeToDate(d, {});

export const endOfDay = (d: Date = new Date()) => applyTimeToDate(d, { hour: 23, minute: 59, second: 59, milli: 999 });

// e.g. 2023-02-23
export type ISODate = string;

export const getDateString = (d: Date = new Date()): ISODate => DateTime.fromJSDate(d).toISODate() || "";

/**
 * returns a list of five successive `ISODate` strings
 * starting from the most recent Monday from `ref` (defaults to today)
 * and ending on Friday
 */
export const getWeekDateStrings = (ref: Date = new Date()) => {
  const startOfWeek = DateTime.fromJSDate(ref).startOf("week");
  return mapExists(getSequence(0, 4), days => startOfWeek.plus({ days }).toISODate());
};
