import { useContext, useEffect } from "react";
import { FieldPath, useForm, UseFormRegister } from "react-hook-form";
import { Dictionary, keyBy, sortBy } from "lodash";
import { observer } from "mobx-react-lite";
import {
  AssessmentConfigGroupData,
  AssessmentDisplayGroupData,
  AssessmentTestData,
} from "@parallel/vertex/types/assessment.types";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { StimulusStoreContext } from "@/store";
import { TestGroup } from "@/store/stimulus.store";
import { isConfigGroup, isDisplayGroup } from "./EditTest";

const CheckboxInput = ({
  register,
  path,
  label,
}: {
  register: UseFormRegister<AssessmentConfigGroupData>;
  path: FieldPath<AssessmentConfigGroupData>;
  label: string;
}) => {
  return (
    <div>
      <input type="checkbox" {...register(path)} className="mr-2" />
      <label htmlFor={path}>{label}</label>
    </div>
  );
};

const SelectInput = ({
  register,
  path,
  label,
  options,
}: {
  register: UseFormRegister<AssessmentConfigGroupData>;
  path: FieldPath<AssessmentConfigGroupData>;
  label: string;
  options: string[];
}) => (
  <div>
    <label htmlFor="layout">{label}</label>
    <br />
    <select {...register(path)} className="border border-grey rounded">
      {options.map((o: string, i: number) => (
        <option value={o} key={i}>
          {o}
        </option>
      ))}
    </select>
  </div>
);

const ConfigGroupForm = ({ group }: { group: AssessmentConfigGroupData }) => {
  const stimulusStore = useContext(StimulusStoreContext);
  const { register, handleSubmit, reset } = useForm<AssessmentConfigGroupData>({ defaultValues: group });
  useEffect(() => reset(group), [reset, group]);
  return (
    <form
      onSubmit={handleSubmit(update => stimulusStore.updateConfigGroup(group.id, update))}
      className="flex flex-col gap-2"
    >
      <h2 className="text-lg italic">Configuration</h2>

      <CheckboxInput register={register} path={"clientStimulus.showClient"} label={"Show Client Stimulus to Client"} />

      <CheckboxInput
        register={register}
        path={"clientStimulus.showProvider"}
        label={"Show Client Stimulus to Provider"}
      />

      <SelectInput
        register={register}
        path={"providerDisplayPriority"}
        label={"Provider Display Priority"}
        options={["Provider Video", "Client Video", "Client Stimulus", "Other"]}
      />

      <CheckboxInput register={register} path={"shareProviderMouse"} label={"Share Provider Mouse"} />

      <CheckboxInput register={register} path={"shareClientMouse"} label={"Share Client Mouse"} />

      <CheckboxInput register={register} path={"stopwatch"} label={"Show Stopwatch"} />

      <button type="submit" className="py-1 px-2 border border-gray-400 bg-slate-200 rounded-md">
        Update
      </button>
    </form>
  );
};

const _DisplaySubgroupForm = ({
  group,
  testsById,
}: {
  group: AssessmentDisplayGroupData;
  testsById: Dictionary<AssessmentTestData>;
}) => {
  const stimulusStore = useContext(StimulusStoreContext);
  const { selectedSubGroupId } = stimulusStore;

  const subgroups = stimulusStore.parseSubgroups(group);
  const newGroupForm = useForm<{ name: string }>();
  const submitNewDisplayGroup = ({ name }: { name: string }) => {
    const maxSubgroup = subgroups.slice(-1)[0];
    const orderIndex = maxSubgroup ? maxSubgroup.orderIndex + 1 : 0;
    const newSubgroup = {
      id: `${group.id}.${orderIndex}`,
      name,
      orderIndex,
      testIds: [],
    };
    stimulusStore.updateDisplayGroup(group.id, { ...group, subgroups: [...subgroups, newSubgroup] });
    newGroupForm.reset();
  };
  const subgroupStyle = (groupId: string) => ({
    textDecorationLine: groupId === selectedSubGroupId ? "underline" : "none",
  });
  return (
    <div>
      <h2 className="text-lg italic mb-2">Subgroups</h2>
      {subgroups.map((g, i) => {
        const tests = sortBy(
          mapExists(g.testIds, id => testsById[id]),
          "orderIndex",
        );
        return (
          <div key={i}>
            <button
              onClick={() => stimulusStore.setSelectedSubGroupId(g.id)}
              className="text-lg"
              style={subgroupStyle(g.id)}
            >
              {g.name}
            </button>
            {tests.map(t => (
              <p key={t.id}>{t.displayName || t.name || t.id}</p>
            ))}
          </div>
        );
      })}
      <form onSubmit={newGroupForm.handleSubmit(submitNewDisplayGroup)}>
        <input {...newGroupForm.register("name")} className="border border-black rounded-md mr-2 mt-2 p-1" />
        <button type="submit">Create</button>
      </form>
    </div>
  );
};

const DisplaySubgroupForm = observer(_DisplaySubgroupForm);

const EditTestGroup = ({ group, allTests }: { group: TestGroup; allTests: AssessmentTestData[] }) => {
  const testsById = keyBy(allTests, "id");
  // TODO figure out a good way to ensure that deleted tests are removed from groups (so we don't have to make this check for non-existence) (https://parallellearning.atlassian.net/browse/ENG-946)
  const tests = sortBy(
    group.testIds.map(t => testsById[t]).filter(t => !!t),
    "orderIndex",
  );
  return (
    <div className="p-2 border border-ocean rounded flex flex-col gap-6">
      <h2 className="text-xl font-semibold">{group.name}</h2>

      {isConfigGroup(group) && <ConfigGroupForm group={group} />}

      <div>
        <h2 className="text-lg mb-2 italic">Member Tests</h2>
        {tests.map(t => (
          <p key={t.id}>{t.displayName || t.name || t.id}</p>
        ))}
      </div>

      {isDisplayGroup(group) && <DisplaySubgroupForm group={group} testsById={testsById} />}
    </div>
  );
};

export default EditTestGroup;
