import { classNames } from "@/utils";

export type SelectOption = {
  name?: string;
  value: string;
};

// deprecated
export type ISelectOption = SelectOption;

export const Select = ({
  label,
  showLabel = false,
  value,
  options,
  onChange,
  defaultValue,
  placeholder,
  disabled,
  className,
}: {
  label: string;
  showLabel?: boolean;
  value?: string;
  options: SelectOption[];
  onChange: (v: string) => void;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}) => {
  const selected = value || (placeholder ? "placeholder" : defaultValue);
  return (
    <div>
      {showLabel && (
        <label htmlFor={label} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <select
        id={label}
        name={label}
        value={selected}
        onChange={e => onChange(e.target.value)}
        className={classNames(
          "mt-1 block rounded-md border-gray-300 py-2 pl-3 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm",
          disabled ? "bg-gray-100" : "bg-white cursor-pointer",
          className,
        )}
        disabled={disabled}
      >
        {placeholder && (
          <option value="placeholder" disabled>
            {placeholder}
          </option>
        )}
        {options.map(({ name, value }) => (
          <option value={value} key={value}>
            {name || value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
