import { createContext } from "react";
import { initLogger } from "@/utils";
import { AlertStore } from "./alert.store";
import { ApiStore } from "./api.store";
import { ReportStore } from "./report.store";
import { StimulusStore } from "./stimulus.store";
import { UserStore } from "./user.store";

export const userStore = new UserStore(initLogger("UserStore"));

export const loggerContext = () => ({
  userId: userStore.currentUser?.userId,
  userRole: userStore.currentUser?.role,
});
userStore.logger.updateContext(loggerContext);

export const alertStore = new AlertStore(initLogger("AlertStore"));

export const apiStore = new ApiStore(alertStore, userStore);
userStore.authApi = apiStore.authApi;

export const reportStore = new ReportStore(
  alertStore,
  apiStore.reportApi,
  apiStore.reportDocumentApi,
  apiStore.reportGuidanceApi,
  initLogger("ReportStore", loggerContext),
);

export const stimulusStore = new StimulusStore(apiStore.stimulusApi);

export const AlertStoreContext = createContext(alertStore);
export const ApiStoreContext = createContext(apiStore);
export const ReportStoreContext = createContext(reportStore);
export const StimulusStoreContext = createContext(stimulusStore);
export const UserStoreContext = createContext(userStore);
