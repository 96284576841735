import { cloneElement, Fragment, ReactNode, useState } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { useWindowSize } from "@react-hookz/web";
import { Route } from "@/routes";
import { classNames } from "@/utils";

const FULL_NAV_MIN_SCREEN_WIDTH_PX = 1200;

type RouteProps = {
  home: Route;
  all: Route[];
};

type UserProps = {
  userName: ReactNode;
  roleName?: string;
  toggleRole?: () => void;
};

const UserNavContent = ({ userName, roleName, toggleRole, signOut }: UserProps & { signOut: () => void }) => {
  const [showRole, setShowRole] = useState(!!roleName);
  return (
    <div className="w-48 max-h-24 overflow-auto no-scrollbar flex flex-col text-mint">
      <span className="font-medium leading-snug">{userName}</span>
      {showRole && !!roleName && (
        <span className="text-sm italic">
          <span>{roleName}</span>
          {!!toggleRole && (
            <>
              <span> - </span>
              <button onClick={toggleRole} className="underline cursor-pointer">
                Switch
              </button>
              <span> | </span>
              <button onClick={() => setShowRole(false)} className="underline cursor-pointer">
                Hide
              </button>
            </>
          )}
        </span>
      )}
      <div>
        <button onClick={signOut} className="text-sm italic underline cursor-pointer">
          Logout
        </button>
      </div>
    </div>
  );
};

const Navigation = ({
  currUser,
  routes,
  navigate,
  signOut,
  logoImageSrc,
  forceCollapse,
}: {
  currUser: UserProps;
  routes: RouteProps;
  navigate: (path: string) => void;
  signOut: () => void;
  logoImageSrc: string;
  forceCollapse?: boolean;
}) => {
  const [isUserCollapsed, setIsUserCollapsed] = useState(false);
  const isCollapsed = forceCollapse || isUserCollapsed;

  const [showUser, setShowUser] = useState(false);

  const isCurrentPath = (path: string) => {
    const currPath = window.location.pathname === "/" ? routes.home.path : window.location.pathname;
    return currPath.indexOf(path) === 0;
  };

  return (
    <div
      className={classNames(
        isCollapsed ? "w-20" : "w-72",
        "relative h-full flex-0 px-3 bg-gray-800 z-10 print:px-0 print:hidden",
      )}
    >
      <div
        onMouseEnter={() => isCollapsed && setShowUser(true)}
        className="relative h-14 my-7 flex place-items-center gap-4"
      >
        <div className="h-14 w-14 p-2 flex-none rounded-md bg-mint">
          <img src={logoImageSrc} alt="Parallel Logo" />
        </div>
        {isCollapsed && showUser && (
          <div onMouseLeave={() => setShowUser(false)} className="absolute -top-1 left-0">
            <div className="ml-16 bg-gray-800 rounded-r-md p-3 pl-4">
              <UserNavContent {...currUser} signOut={signOut} />
            </div>
          </div>
        )}
        {!isCollapsed && <UserNavContent {...currUser} signOut={signOut} />}
      </div>

      <nav className="mt-5 space-y-1 flex-1">
        {routes.all
          .filter(r => !!r.icon)
          .map(({ id, path, friendlyName, icon, isHidden, isExternalLink }) => {
            if (isHidden) return <Fragment key={id}></Fragment>;

            return (
              <a
                href={isExternalLink ? path : undefined}
                target={isExternalLink ? "_blank" : ""}
                rel={isExternalLink ? "noopener noreferrer" : ""}
                onClick={() => (!isExternalLink ? navigate(path) : undefined)}
                className={classNames(
                  isCurrentPath(path) ? "bg-navy text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white",
                  "group flex items-center px-3 py-2 font-medium rounded-md cursor-pointer",
                )}
                data-tooltip-id={`nav-${id}`}
                key={id}
              >
                {icon &&
                  cloneElement(icon, {
                    className: classNames(
                      isCurrentPath(path) ? "text-gray-300" : "text-gray-400 group-hover:text-gray-300",
                      "mr-3 flex-shrink-0 h-8 w-8",
                    ),
                    "aria-hidden": "true",
                  })}
                {isCollapsed ? (
                  <Tooltip id={`nav-${id}`} content={friendlyName} place="right" />
                ) : (
                  <div className="flex relative items-center">
                    <span>{friendlyName}</span>
                    {!!isExternalLink && (
                      <span className="ml-3 inline-flex justify-center leading-none text-gray-300 transform">
                        <ArrowTopRightOnSquareIcon className="text-gray-300 h-4 w-4" />
                      </span>
                    )}
                  </div>
                )}
              </a>
            );
          })}
      </nav>

      <div className="absolute bottom-0 left-0 w-full px-6 pb-4">
        <button
          onClick={() => setIsUserCollapsed(!isCollapsed)}
          className="flex place-items-center text-gray-300 hover:text-white font-medium cursor-pointer"
        >
          {!forceCollapse &&
            (isCollapsed ? (
              <>
                <ChevronRightIcon className="h-8 w-8" id="expand-icon" />
                <Tooltip anchorId="expand-icon" content="Expand" place="right" />
              </>
            ) : (
              <>
                <ChevronLeftIcon className="h-8 w-8 mr-3" />
                <span>Collapse</span>
              </>
            ))}
        </button>
      </div>
    </div>
  );
};

export const SidebarLayout = ({
  currUser,
  routes,
  navigate,
  signOut,
  logoImageSrc,
  bannerContent,
  children,
}: {
  currUser: UserProps;
  routes: RouteProps;
  navigate: (path: string) => void;
  signOut: () => void;
  logoImageSrc: string;
  bannerContent?: ReactNode;
  children: ReactNode;
}) => {
  const windowSize = useWindowSize();
  const forceCollapseNav = windowSize.width <= FULL_NAV_MIN_SCREEN_WIDTH_PX;
  return (
    <div className="w-full h-full flex flex-row">
      <Navigation
        currUser={currUser}
        routes={routes}
        forceCollapse={forceCollapseNav}
        navigate={navigate}
        signOut={signOut}
        logoImageSrc={logoImageSrc}
      />

      <div className="h-full w-0 grow flex flex-col">
        {bannerContent && <div className="py-3 px-8 bg-ocean text-white">{bannerContent}</div>}
        <div className="w-full h-full max-w-7xl mx-auto p-4 sm:px-6 md:px-8 overflow-y-auto print:overflow-y-visible print:p-0">
          {children}
        </div>
      </div>
    </div>
  );
};

/**
 *
 * Alternate sidebar layout that maintains a full-screen layout
 * that allows child components to control the width of child components
 */
export const FullScreenSidebarLayout = ({
  currUser,
  routes,
  navigate,
  signOut,
  logoImageSrc,
  bannerContent,
  children,
}: {
  currUser: UserProps;
  routes: RouteProps;
  navigate: (path: string) => void;
  signOut: () => void;
  logoImageSrc: string;
  bannerContent?: ReactNode;
  children: ReactNode;
}) => {
  const windowSize = useWindowSize();
  const forceCollapseNav = windowSize.width <= FULL_NAV_MIN_SCREEN_WIDTH_PX;
  return (
    <div className="w-full h-full flex flex-row">
      <Navigation
        currUser={currUser}
        routes={routes}
        forceCollapse={forceCollapseNav}
        navigate={navigate}
        signOut={signOut}
        logoImageSrc={logoImageSrc}
      />

      <div className="h-full w-0 grow flex flex-col">
        {bannerContent && <div className="py-3 px-8 bg-ocean text-white">{bannerContent}</div>}
        <div className="w-full h-full overflow-y-auto print:overflow-y-visible print:p-0">{children}</div>
      </div>
    </div>
  );
};
