import { useState } from "react";
import { AssessmentBookRawData } from "@parallel/vertex/types/assessment.types";
import Modal from "@/components/common/content/Modal";

const validateBook = async ({
  id,
  name,
  testPrefix,
  hidden,
}: Partial<AssessmentBookRawData>): Promise<AssessmentBookRawData> => {
  if (!id) throw new Error("missing required `id`");
  if (!name) throw new Error("missing required `name`");
  return { id, name, testPrefix, hidden };
};

const CreateBookModal = ({
  dismiss,
  createBook,
}: {
  dismiss: () => void;
  createBook: (b: AssessmentBookRawData) => void;
}) => {
  const [newBook, setNewBook] = useState<Partial<AssessmentBookRawData>>({});
  return (
    <Modal title="Create New Book" onClose={dismiss}>
      <h2>Full Name (e.g. "WJ Cognitive Std")</h2>
      <input
        value={newBook.name}
        onChange={e =>
          setNewBook({
            ...newBook,
            id: e.target.value,
            name: e.target.value,
          })
        }
        className="w-full border border-grey rounded-md p-1"
      />
      <h2>Test Prefix (e.g. "WJCS")</h2>
      <input
        value={newBook.testPrefix}
        onChange={e =>
          setNewBook({
            ...newBook,
            testPrefix: e.target.value,
          })
        }
        className="w-full border border-grey rounded-md p-1"
      />
      <button
        onClick={async () => {
          const validatedBook = await validateBook(newBook).catch(() => null);
          validatedBook && (await createBook(validatedBook));
          dismiss();
        }}
        className="mt-6 border border-black p-2 rounded-md text-white bg-blue-600"
      >
        Submit
      </button>
    </Modal>
  );
};

export default CreateBookModal;
