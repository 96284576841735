import { useMountEffect } from "@react-hookz/web";
import { observer } from "mobx-react-lite";
import LoginForm from "@/components/common/content/LoginForm";
import logoBrandmarkBlack from "@/images/logo-brandmark-black.png";
import { loggerContext } from "@/store";
import { initLogger } from "@/utils";

const logger = initLogger("LoginScreen", loggerContext);

const LoginScreen = () => {
  useMountEffect(() => logger.mount("LoginScreen"));
  return (
    <div className="w-full h-2/3 grid place-items-center">
      <div className="flex flex-col gap-4 items-center p-12">
        <div className="flex flex-row gap-4 place-items-center mb-4">
          <div className="h-14 w-14 p-2 flex-none rounded-md bg-mint">
            <img src={logoBrandmarkBlack} alt="Parallel Logo" />
          </div>
          <div className="flex flex-col">
            <p className="text-3xl">Parallel Learning</p>
            <p className="text-xl text-gray-600">Internal Tools</p>
          </div>
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default observer(LoginScreen);
