import { useRef, useState } from "react";
import { DocumentPlusIcon } from "@heroicons/react/24/solid";
import { useEventListener } from "@react-hookz/web";
import { classNames } from "@/utils";

const toFileArray = (fs: FileList) => {
  const fileArray: File[] = [];
  for (let i = 0; i < fs.length; i++) {
    fileArray.push(fs[i]);
  }
  return fileArray;
};

const ignoreEvent = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();
};

const useDragHandlers = (handleFileUploads: (fs?: FileList) => void) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isDragover, setIsDragover] = useState(false);
  const toggleDrag = (isDragover: boolean) => (e: Event) => {
    ignoreEvent(e);
    setIsDragover(isDragover);
  };
  const filesDropped = (e: any) => {
    toggleDrag(false)(e);
    handleFileUploads(e.dataTransfer?.files);
  };

  useEventListener(containerRef, "drag", ignoreEvent);
  useEventListener(containerRef, "dragstart", ignoreEvent);
  useEventListener(containerRef, "dragover", toggleDrag(true));
  useEventListener(containerRef, "dragenter", toggleDrag(true));
  useEventListener(containerRef, "dragleave", toggleDrag(false));
  useEventListener(containerRef, "dragend", toggleDrag(false));
  useEventListener(containerRef, "drop", filesDropped);

  return { containerRef, isDragover };
};

export const FileInput = ({
  handleUpload,
  allowMultiple = false,
  validFileTypes,
}: {
  handleUpload: (fs: File[]) => void;
  allowMultiple?: boolean;
  validFileTypes?: string[];
}) => {
  const handleFileUploads = (files?: FileList) => {
    if (files && files.length > 0) {
      if (allowMultiple) {
        handleUpload(toFileArray(files));
      } else {
        handleUpload([files[0]]);
      }
    }
  };

  const { containerRef, isDragover } = useDragHandlers(handleFileUploads);
  return (
    <div
      className={classNames(
        "w-full h-full flex flex-col justify-center items-center gap-2 outline-dashed",
        isDragover ? "bg-success/25 outline-success text-navy" : "bg-white outline-success/30 text-navy",
      )}
      ref={containerRef}
    >
      <div className="w-full">
        <DocumentPlusIcon className="w-20 h-20 m-auto text-success text-opacity-30" />
      </div>
      <input
        accept={validFileTypes ? `${validFileTypes.join(",")}` : undefined}
        type="file"
        id="files"
        multiple={allowMultiple}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleFileUploads(event.target.files || undefined);
          event.target.value = "";
        }}
        className="hidden-active"
        title="Select file"
      />
      <div className="text-center">
        <label htmlFor="files" className="font-bold cursor-pointer text-success hover:text-success/75">
          Select a file to upload
        </label>
        <div>or drag and drop it here</div>
      </div>
    </div>
  );
};

export default FileInput;
