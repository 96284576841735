import { useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { init as initFeathery } from "@feathery/react";
import { useMountEffect } from "@react-hookz/web";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import GlobalFailureModal from "@/components/GlobalFailureModal";
import { SidebarLayout, FullScreenSidebarLayout } from "@/components/common/layout/SidebarLayout";
import config from "@/config";
import logoBrandmarkBlack from "@/images/logo-brandmark-black.png";
import { Route as OrgRoute, allRoutes, protocolRoute, reportHomeRoute } from "@/routes";
import LoadingScreen from "@/screens/LoadingScreen";
import LoginScreen from "@/screens/LoginScreen";
import NotFoundScreen from "@/screens/NotFoundScreen";
import { UserStoreContext, loggerContext } from "@/store";
import { UserRole } from "@/store/user.store";
import { initLogger } from "@/utils";
import { useLoggedRedirect } from "@/utils/redirect.utils";

const logger = initLogger("AppRoot", loggerContext);

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const homeRoute = (role?: UserRole) => (role === UserRole.Provider ? protocolRoute : reportHomeRoute);

const MainContentScreen = observer(({ route }: { route: OrgRoute }) => {
  const userStore = useContext(UserStoreContext);
  const { currentUser } = userStore;
  const isSignedIn = !!currentUser;

  const [isRestoringSignIn, setIsRestoringSignIn] = useState(true);
  const redirect = useLoggedRedirect(logger);

  useMountEffect(() => {
    if (!isSignedIn) {
      userStore
        .restoreSignIn()
        .catch(e => logger.operationError("restoreSignIn", e))
        .finally(() => setIsRestoringSignIn(false));
    } else {
      setIsRestoringSignIn(false);
    }
  });

  if (!isSignedIn) return isRestoringSignIn ? <LoadingScreen /> : <LoginScreen />;

  const currentRole = currentUser.role;

  const roleInvalid = currentRole !== UserRole.Coordinator && route.coordinatorOnly;
  if (roleInvalid) return <NotFoundScreen />;

  const user = {
    userName: currentUser.name,
    roleName: currentRole,
  };
  const navRoutes = {
    home: homeRoute(currentRole),
    all: currentRole === UserRole.Provider ? allRoutes.filter(r => !r.coordinatorOnly) : allRoutes,
  };

  const signOut = () => userStore.signOut();

  // The report writer uses a full-screen layout for smaller screens
  if (route.id === "assessment-report-single") {
    return (
      <FullScreenSidebarLayout
        currUser={user}
        routes={navRoutes}
        navigate={path => redirect(path, "sidbar nav")}
        signOut={signOut}
        logoImageSrc={logoBrandmarkBlack}
      >
        {route.screen}
      </FullScreenSidebarLayout>
    );
  }

  return (
    <SidebarLayout
      currUser={user}
      routes={navRoutes}
      navigate={path => redirect(path, "sidbar nav")}
      signOut={signOut}
      logoImageSrc={logoBrandmarkBlack}
    >
      {route.screen}
    </SidebarLayout>
  );
});

const App = () => {
  const userStore = useContext(UserStoreContext);
  const { currentUser } = userStore;
  const { userId, role } = currentUser || {};

  initFeathery(config.featherySdkKey);

  useEffect(() => {
    Sentry.setUser({ id: userId, role: role });
  }, [userId, role]);

  return (
    <>
      <SentryRoutes>
        <Route path="/" element={<MainContentScreen route={homeRoute(role)} />} />
        {allRoutes.map((r, i) => (
          <Route path={r.path} element={<MainContentScreen route={r} />} key={i} />
        ))}
      </SentryRoutes>
      <GlobalFailureModal />
    </>
  );
};

export default observer(App);
