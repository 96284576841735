import { ReportType } from "../enums/report.enums";
import allPsychInfoSources from "./report/info-sources/psych.json";
import speechInfoSources from "./report/info-sources/speech.json";

export { default as speechInfoSources } from "./report/info-sources/speech.json";
export { default as wjClusterTokens } from "./report/wj-cluster-tokens.json";

export const psychInfoSources = allPsychInfoSources.filter(s => !s.defaultHidden);

type InfoSourceTestingPlan = {
  field: string;
  value?: string;
};

export type InfoSource = {
  key: string;
  name: string;
  search?: string[][];
  tokenMatcher?: string;
  testingPlanForm?: InfoSourceTestingPlan | InfoSourceTestingPlan[];
};

export const reportInfoSources = (type: ReportType): InfoSource[] => {
  switch (type) {
    case ReportType.SpeechComprehensive:
    case ReportType.SpeechSpecialized1:
    case ReportType.SpeechSpecialized2:
      return speechInfoSources;
    case ReportType.SocialEmotional:
      return psychInfoSources.filter(s => s.category !== "performance_measure");
    case ReportType.PsychReview:
      return psychInfoSources.filter(s => s.category && ["review", "interview"].includes(s.category));
    case ReportType.PsychAcademic:
      return allPsychInfoSources.filter(
        s => s.category && ["review", "interview", "performance_measure"].includes(s.category),
      );
    default:
      return psychInfoSources;
  }
};
