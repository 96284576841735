import Modal from "@/components/common/content/Modal";

export type ConfirmSpec = {
  title: string;
  prompt: string;
};
export type ConfirmAction = {
  action: () => void;
  name: string;
  spec: ConfirmSpec;
};

const ConfirmModal = ({ action, spec, onClose }: ConfirmAction & { onClose: () => void }) => (
  <Modal
    title={spec.title}
    onClose={onClose}
    onSubmit={() => {
      onClose();
      action();
    }}
    submitButtonText="Yes"
    closeButtonText="No"
    containerStyle={{ maxWidth: "50%" }}
  >
    <p>{spec.prompt}</p>
  </Modal>
);

export default ConfirmModal;
