import { ReactNode } from "react";
import RegionSelect from "react-region-select";

export type ImageMargins = {
  left: number;
  top: number;
  right: number;
  bottom: number;
};

type ImagePixelArea = { height: number; width: number };
type ImageRelativeArea = { x: number; y: number; width: number; height: number };

const marginsToRelative = (margins: ImageMargins, image: ImagePixelArea) => ({
  x: (margins.left / image.width) * 100,
  y: (margins.top / image.height) * 100,
  width: Math.abs(((margins.right - margins.left) / image.width) * 100),
  height: Math.abs(((margins.bottom - margins.top) / image.height) * 100),
});
const relativeToMargins = (region: ImageRelativeArea, image: ImagePixelArea) => {
  const left = Math.floor(image.width * (region.x / 100));
  const top = Math.floor(image.height * (region.y / 100));
  return {
    left,
    top,
    right: left + Math.ceil(image.width * (region.width / 100)),
    bottom: top + Math.ceil(image.height * (region.height / 100)),
  };
};

export const MarginSelect = ({
  selection,
  setSelection,
  selectableArea,
  children,
}: {
  selection?: ImageMargins;
  setSelection: (m: ImageMargins) => void;
  selectableArea?: ImagePixelArea;
  children?: ReactNode;
}) => {
  const selectedRegion = selection &&
    selectableArea && {
      ...marginsToRelative(selection, selectableArea),
      data: { index: 0 },
    };
  return (
    <RegionSelect
      regions={selectedRegion && [selectedRegion]}
      maxRegions={1}
      onChange={(regions: any) => {
        const region = regions[0];
        if (!selectableArea || !region.width || region.width < 5) return;
        setSelection(relativeToMargins(region, selectableArea));
      }}
      constraint={true}
      className="w-fit h-fit"
    >
      {children}
    </RegionSelect>
  );
};
