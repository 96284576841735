import { ReactNode } from "react";
import { classNames } from "@/utils";

const REPORT_FORM_STEP_STYLE_CLASSES =
  "w-full px-10 max-h-32 bg-white border-slate-200 border flex flex-row xs:space-x-18 md:space-x-36 justify-between items-center";

export const FormStep = ({
  label,
  children: element,
  lastStep,
  className,
}: {
  label: string;
  children: ReactNode;
  lastStep?: boolean;
  className?: string;
}) => {
  return (
    <div className={classNames(className ? className : REPORT_FORM_STEP_STYLE_CLASSES, !lastStep ? "border-b-0" : "")}>
      <label className="text-label">{label}</label>
      <div className="flex flex-row grow max-w-[60%]">
        <div className="w-full py-2 flex flex-row">{element}</div>
      </div>
    </div>
  );
};
