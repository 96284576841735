import { makeAutoObservable } from "mobx";
import { ClientLogger } from "@/utils/logging.utils";

export class AlertStore {
  failedProcessName?: string = undefined;
  isSessionExpired: boolean = false;

  constructor(public logger: ClientLogger) {
    makeAutoObservable(this);
  }

  setFailedProcess(failedProcessName: string, error: Error) {
    this.logger.error(`failed process: ${failedProcessName}`, error);
    this.failedProcessName = failedProcessName;
  }

  clear() {
    this.failedProcessName = undefined;
    this.isSessionExpired = false;
  }
}
