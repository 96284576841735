import { Fragment, useContext, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { StudentRelatedUser } from "@parallel/vertex/types/user/student.types";
import { ApiStoreContext } from "@/store";
import { classNames, initLogger } from "@/utils";

const logger = initLogger("ProviderSelect");

export const ProviderSelect = ({
  clientId,
  startedByUserId,
  selectedProvider,
  setSelectedProvider,
}: {
  clientId: string;
  startedByUserId: string;
  selectedProvider?: StudentRelatedUser;
  setSelectedProvider: (provider?: StudentRelatedUser) => void;
}) => {
  const { userApi } = useContext(ApiStoreContext);

  const [providers, setProviders] = useState<StudentRelatedUser[]>();

  const [_, { execute: getUserById }] = useAsync(() =>
    userApi
      .findStudent(clientId)
      .then(user => setProviders(user?.providers.filter(p => p.userId !== startedByUserId) || []))
      .catch(e => {
        logger.error("error searching users", e);
        setProviders([]);
      }),
  );

  useEffect(
    () => (providers && providers.length > 0 ? setSelectedProvider(providers[0]) : setSelectedProvider(undefined)),
    [providers],
  );

  useMountEffect(getUserById);

  return providers && providers.length > 0 ? (
    <Listbox value={selectedProvider} onChange={setSelectedProvider}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">Select a provider</Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
              <span className="inline-flex w-full truncate">
                <span className="truncate">{selectedProvider?.fullName}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {providers?.map(provider => (
                  <Listbox.Option
                    key={provider.userId}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                      )
                    }
                    value={provider}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex">
                          <span className={classNames(selected ? "font-semibold" : "font-normal", "truncate")}>
                            {provider.fullName}
                          </span>
                          <span className={classNames(active ? "text-indigo-200" : "text-gray-500", "ml-2 truncate")}>
                            {provider.email}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  ) : (
    <span className="flex flex-col items-center text-xl mt-5">No available providers to select</span>
  );
};
