export enum AdminFileKey {
  WISC = "WISC",
  WAIS = "WAIS",
  WIAT = "WIAT",
  WRAML3 = "WRAML3",
  PPVT_5 = "PPVT-5",
  GFTA_3 = "GFTA-3",
  EVT_3 = "EVT-3",
  DKEFS_Standard = "DKEFS Standard",
  DKEFS_Alt = "DKEFS Alt",
  DKEFS_Color = "DKEFS Color",
  CELF_5_1 = "CELF-5 Form (Ages 5-8)",
  CELF_5_2 = "CELF-5 Form (Ages 9-21)",
  CELF_5_Meta = "CELF-5 Meta",
  CELF_3_Preschool = "CELF-3 Preschool",
  CELF_4_Spanish_1 = "CELF-4 Spanish Form 1",
  CELF_4_Spanish_2 = "CELF-4 Spanish Form 2",
  WJ_Achievement_Protocol = "Woodcock-Johnson Achievement Protocol",
  WJ_Achievement_Responses_A = "Woodcock-Johnson Achievement: Form A Response Booklet",
  WJ_Achievement_Responses_B = "Woodcock-Johnson Achievement: Form B Response Booklet",
  WJ_Cognitive_Protocol = "Woodcock-Johnson Cognitive Protocol",
  WJ_Cognitive_Responses = "Woodcock-Johnson Cognitive Abilities: Response Booklet",
  WJ_Oral_Protocol = "Woodcock-Johnson Oral Language Protocol",
  Bateria_IV_Cognitive = "Bateria-IV (Cognitive)",
  Bateria_IV_Achievement = "Bateria-IV (Achievement)",
  WJ_Achievement = "Woodock-Johnson Achievement", // deprecated
  WJ_Cognitive = "Woodcock-Johnson Cognitive", // deprecated
  WJ_Oral = "Woodcock-Johnson Oral Language", // deprecated
}
type MimeType = "application/pdf";
type FileRef = {
  path: string;
  mimeType: MimeType;
  deprecated?: boolean;
};
export const AdminFiles: Record<AdminFileKey, FileRef> = {
  [AdminFileKey.WISC]: {
    path: "WISC-V-Score-Form.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.WAIS]: {
    path: "0154980900_WAIS4_RF_vGL_r1_FNL.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.WIAT]: {
    path: "A103000190569_WIAT4_RF_vGL_r1_FNL.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.WRAML3]: {
    path: "A103000242476_WRAML3_BRIEF_RF_vGL_r1_fnl.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.PPVT_5]: {
    path: "PPVT-5-Score-Form.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.GFTA_3]: {
    path: "0158012836_GFTA3_RF_vGL_r1_FNL.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.EVT_3]: {
    path: "0158013514_EVT3_RF_A_vGL_r2_FNL.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.DKEFS_Standard]: {
    path: "DKEFS_Standard-Score-Form.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.DKEFS_Alt]: {
    path: "DKEFS_Alt-Score-Form.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.DKEFS_Color]: {
    path: "DKEFS_Color-Score-Form.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.CELF_5_1]: {
    path: "0154036323_CELF5_RF1_vGL_r1_FNL.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.CELF_5_2]: {
    path: "0154036331_CELF5_RF2_vGL_r1_FNL.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.CELF_5_Meta]: {
    path: "015803614X_Meta_RF_vGL_r1_FNL.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.CELF_3_Preschool]: {
    path: "A103000185747_CP3_RF_2B_vGL_r1_FNL.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.CELF_4_Spanish_1]: {
    path: "0154038334_RF1_vGL_r1_FNL.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.CELF_4_Spanish_2]: {
    path: "0154038342_RF2_vGL_r1_FNL.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.WJ_Achievement_Protocol]: {
    path: "WJ_Achievement-Score-Form.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.WJ_Achievement_Responses_A]: {
    path: "WJ_Achievement-ResponseBookletA.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.WJ_Achievement_Responses_B]: {
    path: "WJ_Achievement-ResponseBookletB.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.WJ_Cognitive_Protocol]: {
    path: "WJ_Cog-Score-Form.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.WJ_Cognitive_Responses]: {
    path: "WJ_Cog-ResponseBooklet.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.WJ_Oral_Protocol]: {
    path: "WJ_Oral-Score-Form.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.Bateria_IV_Cognitive]: {
    path: "1729875_BateriaIV_COG_TR_061622.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.Bateria_IV_Achievement]: {
    path: "1729886_BateriaIV_ACH_TR_072221.pdf",
    mimeType: "application/pdf",
  },
  [AdminFileKey.WJ_Achievement]: {
    path: "WJ_Achievement-Score-Form.pdf",
    mimeType: "application/pdf",
    deprecated: true,
  },
  [AdminFileKey.WJ_Cognitive]: {
    path: "WJ_Cog-Score-Form.pdf",
    mimeType: "application/pdf",
    deprecated: true,
  },
  [AdminFileKey.WJ_Oral]: {
    path: "WJ_Oral-Score-Form.pdf",
    mimeType: "application/pdf",
    deprecated: true,
  },
};

export const AllAdminFileKeys: AdminFileKey[] = (Object.keys(AdminFiles) as AdminFileKey[]).filter(
  k => !AdminFiles[k].deprecated,
);
