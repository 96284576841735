import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";

export const SearchTextInput = ({
  value,
  setValue,
  placeholder,
}: {
  value: string;
  setValue: (s: string) => void;
  placeholder?: string;
}) => (
  <div className="relative w-96 max-w-full">
    <input
      type="text"
      value={value}
      placeholder={placeholder}
      onChange={e => setValue(e.target.value)}
      className="w-full px-10 border border-gray-300 focus:ring-0 focus:border-black rounded-md"
    />
    <div className="absolute top-0 left-3 h-full grid place-items-center text-gray-400">
      <MagnifyingGlassIcon className="h-5 w-5" />
    </div>
    <div className="absolute top-0 right-3 h-full grid place-items-center text-gray-400">
      <XMarkIcon className="h-5 w-5 cursor-pointer" onClick={() => setValue("")} />
    </div>
  </div>
);

export default SearchTextInput;
