import { captureMessage } from "@sentry/react";
import loggingApi from "@/api/logging.api";
import { initClientLogger } from "./logging.utils";

export const classNames = (...classes: (string | undefined)[]) => classes.filter(Boolean).join(" ");

// makes string usable for HTML id / React key
export const toToken = (s: string) => s.toLowerCase().replace(/ /g, "_");

export const initLogger = (tag: string, commonContext?: () => any) =>
  initClientLogger({
    source: "Org",
    tag,
    postLog: loggingApi.postLog,
    captureIssueMessage: captureMessage,
    commonContext,
  });
