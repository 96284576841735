import { makeAutoObservable } from "mobx";
import { AuthorizedUser } from "@parallel/vertex/types/auth.types";
import { Override } from "@parallel/vertex/types/shared.types";
import { AuthAPI } from "@/api/auth.api";
import { ClientLogger } from "@/utils/logging.utils";

export enum UserRole {
  Coordinator = "Coordinator",
  Provider = "Provider",
}

export type TruncatedUser = {
  userId: string;
  email: string;
  name: string;
  role: UserRole;
  accessToken: string;
};

export class UserStore {
  orgMember?: Override<AuthorizedUser, { email: string }> | null = undefined;
  role?: UserRole = undefined;

  authApi: AuthAPI = {} as AuthAPI;

  get currentUser(): TruncatedUser | undefined | null {
    if (!this.orgMember) return this.orgMember;
    if (!this.role) return null;
    return {
      email: this.orgMember.email,
      role: this.role,
      userId: this.orgMember.userId,
      name: this.orgMember.fullName,
      accessToken: this.orgMember.accessToken,
    };
  }

  get isSignedIn() {
    return !!this.currentUser;
  }

  get simpleUserType() {
    switch (this.currentUser?.role) {
      case UserRole.Coordinator:
        return "admin";
      case UserRole.Provider:
        return "provider";
      default:
        return undefined;
    }
  }

  constructor(public logger: ClientLogger) {
    makeAutoObservable(this);
  }

  async googleSignIn(credential: string) {
    const user = await this.authApi.googleSignIn(credential);
    this.setUser(user);
  }

  async restoreSignIn() {
    const user = await this.authApi.restoreSignIn();
    this.setUser(user);
    return user;
  }

  async signOut() {
    await this.authApi.signOut();
    this.clearUser();
  }

  setUser(user: AuthorizedUser | null) {
    if (!user) {
      this.orgMember = null;
      this.role = undefined;
      return;
    }
    const { email } = user;
    if (!email) {
      this.logger.warn("signed in user has no email - clearing store");
      return this.clearUser();
    }
    this.orgMember = { ...user, email };
    this.role = user.userType === "ADMIN" ? UserRole.Coordinator : UserRole.Provider;
  }

  clearUser() {
    this.orgMember = undefined;
  }

  toggleRole() {
    if (!this.role) return;
    this.role === UserRole.Provider ? (this.role = UserRole.Coordinator) : (this.role = UserRole.Provider);
  }
}
