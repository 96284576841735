const ClickableDiv = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    onKeyUp={e => e.key === "Enter" && props.onClick && props.onClick({} as any)}
    role="button"
    tabIndex={0}
    {...props}
  >
    {props.children}
  </div>
);

export default ClickableDiv;
