import { useContext, useState } from "react";
import { AssessmentTestData } from "@parallel/vertex/types/assessment.types";
import { UpdateTextInput } from "@/components/common/elements/input/UpdateTextInput";
import { StimulusStoreContext } from "@/store";

const EditTestAudioForm = ({ test }: { test: AssessmentTestData }) => {
  const stimulusStore = useContext(StimulusStoreContext);

  const audioPaths = test.audio || [];
  const [isAdding, setIsAdding] = useState(false);

  const editAudioPath = (i: number) => (newValue: string) => {
    const audio = newValue
      ? audioPaths.map((currValue, j) => (i === j ? newValue : currValue))
      : audioPaths.flatMap((currValue, j) => (i === j ? [] : [currValue]));

    stimulusStore.updateTest({ id: test.id, audio });
  };

  const addAudioPath = (newValue: string) => {
    setIsAdding(false);
    newValue && stimulusStore.updateTest({ id: test.id, audio: [...audioPaths, newValue] });
  };

  return (
    <>
      <div className="w-full mt-4 font-semibold">Audio</div>
      {audioPaths.map((a, i) => (
        <UpdateTextInput initialValue={a} persist={editAudioPath(i)} className="w-full" key={i} />
      ))}
      {isAdding ? (
        <UpdateTextInput initialValue={""} persist={addAudioPath} className="w-full" />
      ) : (
        <div className="w-full">
          <button onClick={() => setIsAdding(true)}>Add</button>
        </div>
      )}
    </>
  );
};

export default EditTestAudioForm;
