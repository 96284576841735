import { useContext, useEffect, useState } from "react";
import { useMountEffect } from "@react-hookz/web";
import { observer } from "mobx-react-lite";
import printJS from "print-js";
import { AdminFileKey, AllAdminFileKeys } from "@parallel/vertex/enums/report.file.enums";
import NotPermissable from "@/components/common/content/NotPermissable";
import { Button } from "@/components/common/elements/button/Button";
import CurrentUserClientSelect from "@/components/common/elements/select/CurrentUserClientSelect";
import Select from "@/components/common/elements/select/Select";
import CommonScreenLayout from "@/components/common/windows/OldCommonScreenLayout";
import { AlertStoreContext, ApiStoreContext, UserStoreContext, loggerContext } from "@/store";
import { initLogger } from "@/utils";

const logger = initLogger("DocumentsScreen", loggerContext);

type ClientUser = { userId: string; name: string };

const DocumentsScreen = () => {
  const { reportApi } = useContext(ApiStoreContext);
  const alertStore = useContext(AlertStoreContext);
  const { currentUser } = useContext(UserStoreContext);

  useMountEffect(() => logger.mount("DocumentsScreen"));

  const [clientUser, setClientUser] = useState<ClientUser>();

  const [fileKey, setFileKey] = useState(AllAdminFileKeys[0]);
  const [fileUrl, setFileUrl] = useState<string>();

  useEffect(() => {
    if (!fileUrl) return;
    printJS(fileUrl);

    return () => URL.revokeObjectURL(fileUrl);
  }, [fileUrl]);

  if (!currentUser) return <NotPermissable />;

  const download = (userId: string, clientUserId: string) =>
    logger
      .wrapOperation(
        "downloadPrintableReportForm",
        reportApi.downloadPrintableReportForm(fileKey, userId, clientUserId),
        {
          fileKey,
          userId,
          clientUserId,
        },
      )
      .then(URL.createObjectURL)
      .then(setFileUrl)
      .catch(e => alertStore.setFailedProcess("download file", e));

  const fileOptions = AllAdminFileKeys.map(k => ({ name: k, value: k }));

  return (
    <CommonScreenLayout headerText="Print Assessment Protocol">
      <div className="w-96 h-full flex flex-col gap-4 p-1">
        {!clientUser ? (
          <>
            <h3 className="text-lg">Select Student</h3>
            <CurrentUserClientSelect
              data-testid="client-select"
              userSelected={({ userId, fullName: name }) => setClientUser({ userId, name })}
            />
          </>
        ) : (
          <>
            <h3 className="text-lg">Select Test</h3>
            <Select
              label="file-keys"
              value={fileKey}
              options={fileOptions}
              onChange={k => setFileKey(k as AdminFileKey)}
            />
            <div>
              <Button text="Download" onClick={() => download(currentUser.userId, clientUser.userId)} />
            </div>
            <div>
              <p>For Student: {clientUser.name}</p>
              <button
                onClick={() => setClientUser(undefined)}
                className="text-blue-600 italic underline cursor-pointer text-md"
              >
                Change Student
              </button>
            </div>
          </>
        )}
      </div>
    </CommonScreenLayout>
  );
};

export default observer(DocumentsScreen);
