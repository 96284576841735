import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridSlotsComponentsProps,
  GridToolbar,
} from "@mui/x-data-grid";
import { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";

export type PaginatedTableParams = {
  gridStyles?: object;
  columns: GridColDef[];
  rows: any[];
  rowHeight?: number;
  filterMode: "client" | "server";
  paginationMode: "client" | "server";
  loading?: boolean;
  initialState?: GridInitialStateCommunity;
  rowCount?: number;
  paginationModel?: GridPaginationModel;
  onRowSelect?: (ids: (string | number)[]) => any;
  onRowClick?: (params: any) => any;
  pageSizeOptions?: number[];
  checkboxSelection?: boolean;
  disableColumnFilters?: boolean;
  enableFuzzySearch?: boolean;
  hideToolbar?: boolean;
  hideFooter?: boolean;
  slotProps?: GridSlotsComponentsProps;
  pageSize?: number;
  onFilterChange?: (filter: GridFilterModel) => Promise<void>;
  onPaginationModelChange?: ({ page, pageSize }: { page: number; pageSize: number }) => Promise<void>;
};

const DEFAULT_STYLES = {
  backgroundColor: "white",
  height: "100%",
  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-toolbarContainer": {
    padding: "12px 12px 0px",
  },
};

/**
 *
 * Generic Pagination Table
 * Wraps the Material UI X-DataGrid table for use in `ClientPaginatedTable` and `ServerPaginatedTable`
 *  @params - see `PaginatedTableParams`
 */
const PaginatedTable = ({
  gridStyles,
  columns,
  rows,
  rowHeight,
  initialState,
  rowCount,
  paginationModel,
  filterMode,
  paginationMode,
  loading,
  onRowSelect,
  onRowClick,
  pageSizeOptions,
  checkboxSelection,
  slotProps,
  onFilterChange,
  disableColumnFilters,
  enableFuzzySearch,
  hideToolbar,
  hideFooter,
  pageSize,
  onPaginationModelChange,
}: PaginatedTableParams) => {
  return (
    <div className="h-full">
      <DataGrid
        sx={{ ...gridStyles, ...DEFAULT_STYLES }}
        pageSizeOptions={pageSizeOptions && pageSizeOptions.length > 0 ? pageSizeOptions : [20]}
        rows={rows}
        rowHeight={rowHeight}
        columns={columns}
        disableColumnFilter={disableColumnFilters}
        onRowClick={params => onRowClick && onRowClick(params)}
        rowCount={rowCount ? rowCount : undefined}
        initialState={
          initialState
            ? initialState
            : {
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
                pagination: {
                  paginationModel: { pageSize: pageSize ? pageSize : 20, page: 0 },
                },
              }
        }
        loading={loading}
        onRowSelectionModelChange={ids => onRowSelect && onRowSelect(ids)}
        onPaginationModelChange={
          paginationMode === "server" && onPaginationModelChange ? onPaginationModelChange : undefined
        }
        slots={{ toolbar: hideToolbar ? undefined : GridToolbar }}
        paginationModel={paginationModel ? paginationModel : undefined}
        paginationMode={paginationMode}
        hideFooter={hideFooter}
        filterMode={filterMode}
        onFilterModelChange={filterMode === "server" && onFilterChange ? onFilterChange : undefined}
        slotProps={
          hideToolbar
            ? undefined
            : slotProps
              ? slotProps
              : {
                  toolbar: {
                    showQuickFilter: enableFuzzySearch,
                    printOptions: {
                      disableToolbarButton: true,
                    },
                  },
                }
        }
        keepNonExistentRowsSelected
        ignoreDiacritics
        disableDensitySelector
        checkboxSelection={checkboxSelection}
      />
    </div>
  );
};

export default PaginatedTable;
