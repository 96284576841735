import { PaginatedResult } from "@parallel/vertex/types/shared.types";
import { ExtendedStudentUser, SearchStudentQuery, SingleStudentUser } from "@parallel/vertex/types/user/student.types";
import { AlertStore } from "@/store/alert.store";
import { UserStore } from "@/store/user.store";
import { AuthorizedBaseAPI } from "./base.api";

export class UserAPI extends AuthorizedBaseAPI {
  constructor(alertStore: AlertStore, userStore: UserStore) {
    super(alertStore, userStore);
  }

  public searchStudents = (
    params: SearchStudentQuery,
    signal?: AbortSignal,
  ): Promise<PaginatedResult<ExtendedStudentUser>> =>
    this.instance.get("student", { params, signal }).then(r => r.data);

  public findStudent = (studentId: string): Promise<SingleStudentUser | null> =>
    this.instance
      .get(`student/${studentId}`)
      .then(r => r.data)
      .catch(e => {
        if (e.response?.status === 404) return null;
        else throw e;
      });
}
