import { ReactNode } from "react";

const CommonScreenLayout = ({
  headerText,
  headerControls,
  children,
}: {
  headerText: string;
  headerControls?: ReactNode;
  children: ReactNode;
}) => (
  <div className="w-full h-full pt-6 pb-4 flex flex-col gap-8 text-gray-900">
    <div className="flex flex-row gap-4">
      <h1 className="text-4xl grow">{headerText}</h1>
      {headerControls}
    </div>
    <div className="grow overflow-y-hidden">{children}</div>
  </div>
);

export default CommonScreenLayout;
