import { ReportValueGroup } from "@parallel/vertex/enums/report.enums";
import { AssessmentReport } from "@parallel/vertex/types/report.types";

export const hasPendingValues = (report: AssessmentReport, group: ReportValueGroup) => {
  const values = (report.pendingValues || {})[group] || [];
  return values.length > 0;
};

export const hasInjectedValues = (report: AssessmentReport, group: ReportValueGroup) => {
  const values = (report.injectedValues || {})[group] || [];
  return values.length > 0;
};

export const hasValues = (report: AssessmentReport, group: ReportValueGroup) =>
  hasPendingValues(report, group) || hasInjectedValues(report, group);

export const toHeaderString = (v: string) => {
  switch (v) {
    case ReportValueGroup.HealthieStudentInterviewForm:
      return "Student Interview Notes";
    case ReportValueGroup.HealthieCaregiverInterviewForm:
      return "Caregiver Interview Notes";
    case ReportValueGroup.HealthieTeacherInterviewForm:
      return "Teacher Interview Notes";
    case ReportValueGroup.HealthieInterviewForm:
      return "Clinical Interview Notes";
    default:
      return v.replace(/-/g, " ");
  }
};
