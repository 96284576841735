import { useEffect, useMemo, useState } from "react";
import { ClipLoader } from "react-spinners";
import { debounce } from "lodash";

export type ItemFetchFn<A> = (search: string, signal: AbortSignal) => Promise<A[]>;

export type AutoCompleteSearchProps<A> = {
  searchValue: string;
  fetchItems: ItemFetchFn<A>;
  displayItem: (item: A) => { name: string; key: string };
  itemSelected: (item: A) => void;
  isFocused: boolean;
  setIsFocused: (isFocused: boolean) => void;
  debounceRateMs?: number;
};

const performFetch = <A,>(
  fetch: ItemFetchFn<A>,
  search: string,
  itemsLoaded: (items: any[]) => void,
  signal: AbortSignal,
) =>
  fetch(search, signal)
    .then(itemsLoaded)
    .catch(e => {
      console.error("error searching items", e);
      itemsLoaded([]);
    });

const AutoCompleteResults = <A,>({
  searchValue,
  fetchItems,
  displayItem,
  itemSelected,
  isFocused,
  setIsFocused,
  debounceRateMs = 500,
}: AutoCompleteSearchProps<A>) => {
  const [matchingItems, setMatchingItems] = useState<A[]>();
  const [isLoading, setIsLoading] = useState(false);

  const debouncedFetch = useMemo(() => debounce(performFetch, debounceRateMs), [debounceRateMs]);

  const itemsLoaded = (items: A[]) => {
    setMatchingItems(items);
    setIsLoading(false);
    setIsFocused(true);
  };

  useEffect(() => {
    setIsFocused(!!searchValue);
    if (!searchValue) {
      setIsFocused(false);
      return;
    }
    const controller = new AbortController();
    setIsLoading(true);
    debouncedFetch(fetchItems, searchValue, itemsLoaded, controller.signal);
    return () => controller.abort();
  }, [searchValue]);

  if (!isFocused || !searchValue) return <></>;

  return (
    <div className="relative">
      <ul className="absolute top-0 left-0 w-full z-50 bg-white rounded-b-md shadow divide-y divide-gray-200 border-t border-gray-200 max-h-48 overflow-y-auto">
        {isLoading && (
          <div className="absolute top-0 left-0 w-full h-full grid place-items-center">
            <div className="absolute top-0 left-0 w-full h-full bg-gray-500 opacity-10 rounded-b-md" />
            <ClipLoader />
          </div>
        )}
        {!matchingItems || matchingItems.length === 0 ? (
          <div className="w-full h-16 grid place-items-center">{!!matchingItems && "No Matches Found"}</div>
        ) : (
          matchingItems.map(item => {
            const { key, name } = displayItem(item);
            return (
              <li key={key}>
                <div
                  className="relative w-full h-12 px-4 flex items-center hover:bg-gray-50 cursor-pointer"
                  onMouseDown={e => {
                    itemSelected(item);
                    e.stopPropagation();
                  }}
                >
                  {name}
                </div>
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
};

export default AutoCompleteResults;
