import { HomeIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import { format } from "fecha";
import { AssessmentReport } from "@parallel/vertex/types/report.types";
import { Button } from "@/components/common/elements/button/Button";
import { loggerContext } from "@/store";
import { initLogger } from "@/utils";
import { useLoggedRedirect } from "@/utils/redirect.utils";
import ConfigWindowLayout from "./ConfigWindowLayout";

const PARAGRAPH_STYLE_CLASSES = "text-lg max-w-[550px] text-justify";
const logger = initLogger("AssessmentReportSingle", loggerContext);

const DeletedReportLayout = ({ report }: { report: AssessmentReport }) => {
  const reportDeletedDate = report?.deletedAt?.isoString ? new Date(report?.deletedAt?.isoString) : undefined;

  const redirect = useLoggedRedirect(logger);

  return (
    <ConfigWindowLayout title="Deleted Report" isSectionLocked={true}>
      <div className="w-full flex gap-4 bg-white">
        <div className="w-full gap-8 text-center">
          <div className="flex w-full py-4 justify-center">
            <LockClosedIcon className="flex flex-row w-36 h-36 text-failure-dark text-opacity-50" />
          </div>

          <div className="flex flex-col gap-8 items-center">
            {reportDeletedDate && (
              <div className={`flex flex-col ${PARAGRAPH_STYLE_CLASSES}`}>
                Date Deleted: {format(reportDeletedDate, "MMMM DD, YYYY")}
              </div>
            )}
            <div className={`flex flex-col ${PARAGRAPH_STYLE_CLASSES}`}>
              The report has been deleted, and it cannot be edited further. A new report for this student may now be
              created.
            </div>
            <div>
              <Button
                text="Back to home"
                icon={<HomeIcon />}
                onClick={() => redirect("/assessment/report", "deleted report")}
              />
            </div>
          </div>
        </div>
      </div>
    </ConfigWindowLayout>
  );
};

export default DeletedReportLayout;
