import { BarLoader } from "react-spinners";

export const LoadingModal = ({ subtext }: { subtext?: string }) => (
  <div className="absolute w-full h-full top-0 left-0 grid place-items-center z-50">
    <div className="absolute w-full h-full bg-black opacity-70" />
    <div className="absolute w-72 h-44 p-8 gap-6 text-white bg-gray-800 rounded-md flex flex-col justify-center items-center">
      <div className="flex flex-col place-items-center gap-1">
        <p className="text-2xl">Loading</p>
        {subtext && <p className="italic font-light text-center">{subtext}</p>}
      </div>
      <BarLoader color="white" />
    </div>
  </div>
);
