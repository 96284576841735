import { useContext, useEffect, useState } from "react";
import { FadeLoader, PuffLoader } from "react-spinners";
import { Form, getFieldValues, updateUserId } from "@feathery/react";
import { isNull, isUndefined } from "lodash";
import { ExtendedFormSubmission } from "@parallel/vertex/types/form.types";
import { Link } from "@/components/common/elements/Link";
import { ApiStoreContext, ReportStoreContext } from "@/store";

const FormSubmission = ({
  submissionId,
  isReadOnly,
  onSubmit,
}: {
  submissionId: string;
  isReadOnly?: boolean;
  onSubmit?: (data: Record<string, any>) => Promise<void>;
}) => {
  const { formApi } = useContext(ApiStoreContext);
  const reportStore = useContext(ReportStoreContext);

  const [isComplete, setIsComplete] = useState(false);

  const [submission, setSubmission] = useState<ExtendedFormSubmission | null>();
  useEffect(() => {
    updateUserId(submissionId)
      .then(() => new Promise(resolve => setTimeout(resolve, 1000)))
      .then(() => formApi.getSubmission(submissionId))
      .then(setSubmission)
      .catch(e => {
        console.error("error fetching submission", e);
        setSubmission(null);
      });
  }, [submissionId]);

  const onFormComplete = async () => {
    const data = getFieldValues();
    await reportStore.performUpdate("submit form answers", () =>
      onSubmit ? onSubmit(data) : formApi.updateSubmission(submissionId, data).then(() => {}),
    );
    setIsComplete(true);
  };

  return (
    <div className="w-full h-full">
      {isUndefined(submission) && (
        <div className="w-full pt-8 grid place-items-center">
          <FadeLoader />
        </div>
      )}
      {isNull(submission) && (
        <p>Unable to load testing plan - Please refresh the page and contact Parallel support if the issue persists.</p>
      )}
      {isComplete && (
        <p>
          Successfully Submitted Form - <Link onClick={() => setIsComplete(false)}>Show Answers</Link>
        </p>
      )}
      {!!submission && !isComplete && (
        <Form
          formId={submission.featheryFormId}
          initialLoader={{
            show: true,
            loader: <PuffLoader />,
          }}
          readOnly={isReadOnly}
          onFormComplete={onFormComplete}
          hideTestUI={true}
          className="form-wrapper"
        />
      )}
    </div>
  );
};

export default FormSubmission;
