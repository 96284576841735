import { RefObject, useEffect, useRef, useState, useContext } from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useKeyboardEvent, useMountEffect } from "@react-hookz/web";
import { captureMessage } from "@sentry/react";
import { last, pick } from "lodash";
import { observer } from "mobx-react-lite";
import { v4 as uuidv4 } from "uuid";
import { AssessmentBookRawData, AssessmentTestData } from "@parallel/vertex/types/assessment.types";
import { StimulusAPI } from "@/api/stimulus.api";
import CreateBookModal from "@/components/assessment/stimulus/upload/CreateBookModal";
import PdfViewer from "@/components/assessment/stimulus/upload/PdfViewer";
import { ImageMargins } from "@/components/common/elements/input/MarginSelect";
import { ApiStoreContext, StimulusStoreContext, loggerContext } from "@/store";
import { initLogger } from "@/utils";
import { useLoggedRedirect } from "@/utils/redirect.utils";

const logger = initLogger("StimulusUploadScreen", loggerContext);

const checkProperty = (t: any, prop: string, setError: (s: string) => void) => {
  if (!t[prop] && t[prop] !== 0) {
    const msg = `required property ${prop} not found`;
    setError(msg);
    logger.error(msg, t);
    return false;
  }
  return true;
};
const isValidTest = (t: any, setError: (s: string) => void) => {
  if (!checkProperty(t, "id", setError)) return false;
  if (!checkProperty(t, "name", setError)) return false;
  if (!checkProperty(t, "orderIndex", setError)) return false;
  return true;
};

const isMarginError = (m?: ImageMargins) => !!m && (m.left > m.right || m.top > m.bottom);

export const findMarginErrors = (test: AssessmentTestData) => {
  const clientError = isMarginError(test.image.clientMargin) && pick(test.image, ["client", "clientMargin"]);
  const staffError = isMarginError(test.image.staffMargin) && pick(test.image, ["staff", "staffMarginclientMargin"]);
  if (!clientError && !staffError) return;
  return { clientError, staffError };
};

const uploadCanvas = (
  stimulusApi: StimulusAPI,
  canvasRef: RefObject<HTMLCanvasElement | undefined>,
  bookName: string,
  testId: string,
): Promise<File> => {
  return new Promise((resolve, reject) => {
    if (!canvasRef.current) {
      reject(new Error("canvas ref not ready"));
      return;
    }
    try {
      canvasRef.current.toBlob(async imageData => {
        try {
          if (!imageData) {
            reject(new Error("unable to get blob from canvas"));
            return;
          }
          const file = await stimulusApi.uploadImage(bookName, testId, imageData);
          resolve(file);
        } catch (e) {
          logger.error("error uploading stimulus image", e);
          reject(e);
        }
      }, "image/png");
    } catch (e) {
      reject(e);
    }
  });
};

const MarginDisplay = ({ margins }: { margins: ImageMargins }) => (
  <>
    <div className="w-full flex flex-row">
      <span className="w-16">Left</span>
      <span className="w-16">{margins.left}px</span>
    </div>
    <div className="w-full flex flex-row">
      <span className="w-16">Top</span>
      <span className="w-16">{margins.top}px</span>
    </div>
    <div className="w-full flex flex-row">
      <span className="w-16">Right</span>
      <span className="w-16">{margins.right}px</span>
    </div>
    <div className="w-full flex flex-row">
      <span className="w-16">Bottom</span>
      <span className="w-16">{margins.bottom}px</span>
    </div>
  </>
);

const StimulusUploadScreen = () => {
  const { stimulusApi } = useContext(ApiStoreContext);
  const stimulusStore = useContext(StimulusStoreContext);
  const allBooks = stimulusStore.books;

  useMountEffect(() => logger.mount("StimulusUploadScreen"));

  const redirect = useLoggedRedirect(logger);

  const [testBook, setTestBook] = useState<AssessmentBookRawData>();
  const [bookTests, setBookTests] = useState<AssessmentTestData[]>([]);
  const [insertAfterTest, setInsertAfterTest] = useState<AssessmentTestData>();
  const [test, setTest] = useState<AssessmentTestData>({ image: {} } as AssessmentTestData);

  const [message, setMessage] = useState<{ text: string; isError?: boolean; isSuccess?: boolean }>();

  const setTestBookProps = (book = testBook) => {
    const orderIndex = (insertAfterTest?.orderIndex || 0) + 1;
    let newId = `${book?.testPrefix || "XXXX"}-${orderIndex}`;
    if (bookTests.find(t => t.id === newId)) newId += `-${uuidv4().split("-")[0]}`;

    const newProps = {
      ...test,
      id: newId,
      orderIndex,
      image: {
        ...test.image,
        staff: "",
      },
    };
    setTest(newProps);
  };
  useEffect(setTestBookProps, [insertAfterTest, testBook]);

  const loadBookTests = async () => {
    if (!testBook) return;
    const { tests } = await stimulusApi.getBookMetadata(testBook.id);
    setBookTests(tests);
    setInsertAfterTest(tests.find(t => t.id === test.id) || last(tests));
  };
  useEffect(() => {
    loadBookTests();
  }, [testBook]);

  const [isProviderOnly, setIsProviderOnly] = useState(false);
  const [showCreateBookModal, setShowCreateBookModal] = useState(false);

  const providerCanvasRef = useRef<HTMLCanvasElement>(null);
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const testNameInputRef = useRef<HTMLInputElement>(null);

  const writeTest = async () => {
    if (!testBook) {
      setMessage({ text: "must set a book", isError: true });
      return;
    }
    if (bookTests.find(t => t.id === test.id)) {
      const msg = `test w/ id ${test.id} already exists`;
      setMessage({ text: msg, isError: true });
      logger.error(msg);
      return;
    }
    if (!isValidTest(test, (text: string) => setMessage({ text, isError: true }))) return;

    try {
      const bumpOrderIndexTests = bookTests.filter(t => t.orderIndex >= test.orderIndex);

      setMessage({ text: `writing test ${test.id} to book ${testBook.id}` });
      logger.info("writing test", { test, testBook, bumpOrderIndexTests });

      await Promise.all(
        bumpOrderIndexTests.map(t =>
          stimulusApi.updateTests(testBook.id, [{ id: t.id, orderIndex: t.orderIndex + 1 }]),
        ),
      );

      const providerFile = await uploadCanvas(stimulusApi, providerCanvasRef, testBook.name, `${test.id}-provider`);
      const clientFile = isProviderOnly
        ? undefined
        : await uploadCanvas(stimulusApi, clientCanvasRef, testBook.name, `${test.id}-client`);

      test.image.staff = `/assessments/${testBook.name}/${providerFile.name}`;
      if (clientFile) test.image.client = `/assessments/${testBook.name}/${clientFile.name}`;
      await stimulusApi.createTest(testBook.id, test);

      const marginError = findMarginErrors(test);
      if (marginError)
        captureMessage("wrote new test with invalid margins", {
          level: "warning",
          contexts: { details: { marginError } },
        });
    } catch (e) {
      setMessage({ text: `error writing test record ${test.id} - error: ${e}`, isError: true });
      throw e;
    }

    await loadBookTests();
    setMessage({ text: `wrote test record ${test.id}`, isSuccess: true });
    logger.info("wrote test record", { test });
    testNameInputRef.current?.focus();
  };

  useKeyboardEvent(
    e => e.ctrlKey && e.key === "Enter",
    e => {
      e.preventDefault();
      writeTest();
    },
  );

  return (
    <div className="w-full h-full flex flex-col gap-4">
      <h2 className="text-2xl">Creating New Test Stimulus Book</h2>
      <button onClick={() => redirect("/stimulus", "back click")} className="flex flex-row gap-2 items-center mt-2">
        <ArrowLeftIcon className="w-5 h-5" />
        Back to Book Select
      </button>

      <div className="w-full grow flex flex-row gap-12">
        <div className="w-64 h-full py-6 pr-4 overflow-y-auto">
          <h1 className="text-2xl mb-4">Test Import</h1>

          <h2 className="mt-6 font-semibold">Book</h2>
          <select
            onChange={e => {
              const newBook = (allBooks || []).find(b => b.id === e.target.value);
              setTestBook(newBook);
            }}
            value={testBook?.id}
            className="w-full border border-grey rounded-md p-1"
          >
            <option>SELECT BOOK</option>
            {(allBooks || []).map(b => (
              <option value={b.id} key={b.id}>
                {b.name}
              </option>
            ))}
          </select>
          <button onClick={() => setShowCreateBookModal(true)}>Create New Book</button>

          <h2 className="mt-6 font-semibold">Test Name</h2>
          <input
            value={test.displayName}
            onChange={e => {
              const newName = e.target.value;
              setTest({
                ...test,
                displayName: newName,
                name: newName,
              });
            }}
            ref={testNameInputRef}
            className="w-full border border-grey rounded-md p-1"
          />

          <h2 className="mt-6 font-semibold">Insert After</h2>
          <select
            onChange={e => setInsertAfterTest((bookTests || []).find(t => t.id === e.target.value))}
            value={insertAfterTest?.id}
            className="w-full border border-grey rounded-md p-1"
          >
            <option>----</option>
            {bookTests.map(t => (
              <option value={t.id} key={t.id}>
                {t.name}
              </option>
            ))}
          </select>

          <div className="mt-6 pl-1 flex content-center gap-4">
            <input type="checkbox" checked={isProviderOnly} onChange={() => setIsProviderOnly(!isProviderOnly)} />
            <h2 className="font-semibold">Is Provider Only</h2>
          </div>

          <h2 className="mt-6 font-semibold">Provider Margins</h2>
          {test.image?.staffMargin && <MarginDisplay margins={test.image.staffMargin} />}

          <h2 className="mt-6 font-semibold">Client Margins</h2>
          {test.image?.clientMargin && <MarginDisplay margins={test.image.clientMargin} />}

          <button onClick={writeTest} className="mt-8 border border-black p-2 rounded-md text-white bg-blue-600">
            Submit
          </button>
        </div>

        <div className="h-full w-0 grow flex flex-col py-6">
          <div
            className={`w-full h-12 px-6 ${
              message?.isError ? "text-red-600" : message?.isSuccess ? "text-green-600" : ""
            }`}
          >
            {message?.text}
          </div>

          <div className="w-full h-0 grow flex">
            <div className="w-1/2 h-full flex-none p-6 pt-0">
              <h1 className="text-xl">Provider</h1>
              <PdfViewer
                canvasRef={providerCanvasRef}
                margins={test.image?.staffMargin}
                setMargins={staffMargin =>
                  setTest({
                    ...test,
                    image: {
                      ...test.image,
                      staffMargin,
                    },
                  })
                }
              />
            </div>
            <div className={`w-1/2 h-full flex-none p-6 pt-0 ${isProviderOnly ? "opacity-0" : ""}`}>
              <h1 className="text-xl">Client</h1>
              <PdfViewer
                canvasRef={clientCanvasRef}
                margins={test.image?.clientMargin}
                setMargins={clientMargin =>
                  setTest({
                    ...test,
                    image: {
                      ...test.image,
                      clientMargin,
                    },
                  })
                }
                isClient={true}
              />
            </div>
          </div>
        </div>
        {showCreateBookModal && allBooks && (
          <CreateBookModal
            dismiss={() => setShowCreateBookModal(false)}
            createBook={async b => {
              const existing = allBooks.find(c => c.id === b.id);
              if (existing) {
                setTestBook(existing);
                return;
              }
              const newBook = { ...b, hidden: true, orderIndex: allBooks.length };
              await stimulusStore.createBook(newBook);
              setTestBook(newBook);
              setTestBookProps(newBook);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default observer(StimulusUploadScreen);
