import { ChangeEvent, RefObject, useEffect, useRef, useState } from "react";
import { useKeyboardEvent, useMeasure } from "@react-hookz/web";
import * as pdfjs from "pdfjs-dist";
import { PDFDocumentProxy, PDFPageProxy, PageViewport } from "pdfjs-dist";
import { ImageMargins, MarginSelect } from "@/components/common/elements/input/MarginSelect";
import { loggerContext } from "@/store";
import { initLogger } from "@/utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

const UPLOAD_SCALE = 3;

type PixelArea = {
  width: number;
  height: number;
};
const aspectRatio = (area: PixelArea) => area.width / area.height;
const isWider = (a1: PixelArea, a2: PixelArea) => aspectRatio(a1) > aspectRatio(a2);

const logger = initLogger("PdfViewer", loggerContext);

const PdfViewer = ({
  canvasRef,
  margins,
  setMargins,
  isClient,
}: {
  canvasRef: RefObject<HTMLCanvasElement>;
  margins?: ImageMargins;
  setMargins: (m: ImageMargins) => void;
  isClient?: boolean;
}) => {
  const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy>();
  const [pageNumber, setPageNumber] = useState(1);
  const [uploadViewport, setUploadViewport] = useState<PageViewport>();

  const [containerArea, containerRef] = useMeasure<HTMLDivElement>();

  const displayCanvasRef = useRef<HTMLCanvasElement>(null);

  const loadPdf = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.onload = function () {
      if (!(this.result instanceof ArrayBuffer)) return;
      const typedarray = new Uint8Array(this.result);
      pdfjs
        .getDocument(typedarray)
        .promise.then(setPdfDocument)
        .catch(e => logger.error("error parsing document", e));
    };
    fileReader.readAsArrayBuffer(file);
  };

  const getViewports = (page: PDFPageProxy): [PageViewport, PageViewport] | undefined => {
    const uploadCanvas = canvasRef?.current;
    const displayCanvas = displayCanvasRef?.current;
    if (!containerArea || !uploadCanvas || !displayCanvas) return;
    if (containerArea.width === 0 || containerArea.height === 0) return;

    const uploadViewport = page.getViewport({ scale: UPLOAD_SCALE });
    uploadCanvas.height = uploadViewport.height;
    uploadCanvas.width = uploadViewport.width;

    const scale = isWider(uploadViewport, containerArea)
      ? (containerArea.width / uploadViewport.width) * UPLOAD_SCALE
      : (containerArea.height / uploadViewport.height) * UPLOAD_SCALE;

    const displayViewport = page.getViewport({ scale });
    displayCanvas.height = displayViewport.height;
    displayCanvas.width = displayViewport.width;

    setUploadViewport(uploadViewport);
    return [displayViewport, uploadViewport];
  };

  useEffect(() => {
    if (!pdfDocument || !canvasRef?.current || !displayCanvasRef.current) return;
    pdfDocument.getPage(pageNumber).then(async page => {
      const canvasContext = canvasRef.current?.getContext("2d");
      const displayCanvasContext = displayCanvasRef.current?.getContext("2d");
      const [displayViewport, fullSizeViewport] = getViewports(page) || [];
      if (!canvasContext || !displayCanvasContext || !displayViewport || !fullSizeViewport) return;

      await page.render({ canvasContext, viewport: fullSizeViewport, intent: "print" }).promise;
      await page.render({ canvasContext: displayCanvasContext, viewport: displayViewport }).promise;
    });
  }, [pdfDocument, pageNumber, canvasRef, displayCanvasRef, containerArea]);

  const [pageNumberInput, setPageNumberInput] = useState<number>(pageNumber);
  useEffect(() => setPageNumberInput(pageNumber), [pageNumber]);

  useKeyboardEvent(
    ({ shiftKey, altKey }) => (isClient ? altKey : shiftKey),
    e => {
      if (e.key === "ArrowRight") {
        e.preventDefault();
        setPageNumber(pageNumber + 1);
      }
      if (e.key === "ArrowLeft" && pageNumber !== 1) {
        e.preventDefault();
        setPageNumber(pageNumber - 1);
      }
    },
  );

  return (
    <div className="w-full h-full flex flex-col gap-2 mt-2">
      <input type="file" onChange={loadPdf} />
      <div ref={containerRef} className="w-full h-0 grow">
        <MarginSelect selection={margins} setSelection={setMargins} selectableArea={uploadViewport}>
          <canvas ref={displayCanvasRef} className="border border-gray-200" />
        </MarginSelect>
        <canvas ref={canvasRef} className="hidden" />
      </div>
      <div className="flex w-full h-12 gap-2 py-2">
        <button
          onClick={() => pageNumber !== 1 && setPageNumber(pageNumber - 1)}
          className="h-full p-1 border border-gray-300 rounded-md"
        >
          Prev
        </button>
        <form
          onSubmit={e => {
            e.preventDefault();
            pageNumberInput && setPageNumber(pageNumberInput);
          }}
        >
          <input
            type="text"
            value={pageNumberInput || ""}
            onChange={e => {
              const newValue = parseInt(e.target.value, 10);
              setPageNumberInput(newValue);
            }}
            className="p-1 h-full w-12 border border-gray-300 rounded-md"
          />
        </form>
        <button onClick={() => setPageNumber(pageNumber + 1)} className="h-full p-1 border border-gray-300 rounded-md">
          Next
        </button>
      </div>
    </div>
  );
};

export default PdfViewer;
