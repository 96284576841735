import { Link } from "@/components/common/elements/Link";

const NotFoundScreen = () => {
  return (
    <div className="w-full h-full grid place-items-center">
      <div className="flex flex-col gap-4 items-center">
        <p>The route you are trying to access is not available</p>
        <Link href="/" newTab={false}>
          Back to Home Page
        </Link>
      </div>
    </div>
  );
};

export default NotFoundScreen;
