import { useContext, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { noop } from "lodash";
import { Button, ButtonStyleClass, DEFAULT_BUTTON_CLASS } from "@/components/common/elements/button/Button";
import config from "@/config";
import { UserStoreContext } from "@/store";
import { initLogger } from "@/utils";

const LOGIN_ERROR_PREAMBLE = "Unexpected error signing in";
const LOGIN_ERROR_MESSAGE = {
  retry: `${LOGIN_ERROR_PREAMBLE} - Please try again and contact support if the issue persists`,
  ensure: `${LOGIN_ERROR_PREAMBLE} - Please ensure you are signing in as a valid user and contact support if the issue persists`,
};

const logger = initLogger("LoginForm");

const LoginForm = ({ onSuccess = noop }: { onSuccess?: () => void }) => {
  const userStore = useContext(UserStoreContext);
  const [errorMsg, setErrorMsg] = useState("");

  const performGoogleLogin = useGoogleLogin({
    onSuccess: ({ access_token: accessToken }) => {
      userStore
        .googleSignIn(accessToken)
        .then(onSuccess)
        .catch(e => {
          logger.error("error verifying google login response", e);
          setErrorMsg(LOGIN_ERROR_MESSAGE.ensure);
        });
    },
    onError: response => {
      logger.error("GoogleLogin component error", { response });
      setErrorMsg(LOGIN_ERROR_MESSAGE.retry);
    },
  });

  return (
    <div className="flex flex-col gap-4 items-center">
      <div>
        <Button
          text="Sign In With Google"
          icon={
            <img
              alt="Google Login Button"
              src={`${config.staticAssetsUrl}/logos/google-logo.svg`}
              style={{ height: 20, width: 20, marginRight: 4 }}
            />
          }
          onClick={performGoogleLogin}
          styleClass={ButtonStyleClass.Secondary}
          className={`${DEFAULT_BUTTON_CLASS} w-full`}
        />
      </div>
      <p>{errorMsg}</p>
    </div>
  );
};

export default LoginForm;
