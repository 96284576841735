import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useMountEffect } from "@react-hookz/web";
import { observer } from "mobx-react-lite";
import EditTestGroup from "@/components/assessment/stimulus/edit/EditTestGroup";
import { ConfigGroupList, DisplayGroupList } from "@/components/assessment/stimulus/edit/TestGroupList";
import TestList from "@/components/assessment/stimulus/edit/TestList";
import { StimulusStoreContext, loggerContext } from "@/store";
import { initLogger } from "@/utils";
import { useLoggedRedirect } from "@/utils/redirect.utils";

const logger = initLogger("StimulusEditScreen", loggerContext);

const StimulusEditScreen = () => {
  const { bookId } = useParams();

  const stimulusStore = useContext(StimulusStoreContext);
  const { activeBook: book, selectedRootGroup: selectedParentGroup } = stimulusStore;

  const redirect = useLoggedRedirect(logger);

  useMountEffect(() => logger.mount("StimulusEditScreen"));

  useEffect(() => {
    if (!bookId) return;
    stimulusStore.selectBook(bookId);
  }, [bookId]);

  if (!book) return <></>;

  return (
    <div className="w-full h-full flex flex-col gap-4">
      <div>
        <h2 className="text-2xl">Editing Test Stimulus Book {book.name}</h2>
        <button onClick={() => redirect("/stimulus", "back click")} className="flex flex-row gap-2 items-center mt-2">
          <ArrowLeftIcon className="w-5 h-5" />
          Back to Book Select
        </button>
      </div>

      <div className="w-full px-2 grow flex flex-row overflow-y-hidden bg-white border border-gray-200">
        <div className="h-full w-2/6 p-3 overflow-y-auto">
          <h3 className="text-2xl mb-2">Tests</h3>
          <TestList book={book} />
        </div>

        <div className="h-full w-2/6 p-3 overflow-y-auto">
          <h3 className="text-2xl mb-4">Display Groups</h3>
          <DisplayGroupList groups={book.displayGroups} selectedGroup={selectedParentGroup} />
          <h3 className="text-2xl mb-2 mt-8">Configuration Groups</h3>
          <ConfigGroupList groups={book.configGroups} selectedGroup={selectedParentGroup} />
        </div>

        <div className="h-full w-2/6 p-3 overflow-y-auto">
          <h3 onClick={() => stimulusStore.setSelectedSubGroupId(undefined)} className="text-2xl mb-2 cursor-pointer">
            Selected Group
          </h3>
          {selectedParentGroup ? (
            <EditTestGroup group={selectedParentGroup} allTests={book.tests} />
          ) : (
            <p className="italic">Select a group</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(StimulusEditScreen);
