import { useContext, useState } from "react";
import { CheckCircleIcon, CogIcon, ArrowPathIcon, WrenchScrewdriverIcon } from "@heroicons/react/20/solid";
import { Square2StackIcon, ArrowTopRightOnSquareIcon, CheckIcon, ShareIcon } from "@heroicons/react/24/outline";
import { observer } from "mobx-react-lite";
import { ReportStatus } from "@parallel/vertex/enums/report.enums";
import { HydratedAssessmentReport } from "@parallel/vertex/types/report.types";
import { Button, ButtonStyleClass } from "@/components/common/elements/button/Button";
import { ApiStoreContext, ReportStoreContext } from "@/store";
import { classNames } from "@/utils";
import { ReportEditorSection, ReportConfigSection } from "../EditReport";
import ShareReportModal from "../modals/ShareReportModal";
import { ReportProgressStepper } from "./ReportProgressStepper";

const HEADER_BUTTON_STYLE_CLASSES = "text-ocean hover:text-navy";

const EditReportHeader = ({
  hydratedReport: { report, user },
  showPropertiesPage,
  setIsUpdatingDoc,
  isSavingReport = false,
  currSection,
}: {
  hydratedReport: HydratedAssessmentReport;
  showPropertiesPage: () => any;
  setIsUpdatingDoc: (b: boolean) => void;
  isSavingReport?: boolean;
  currSection?: ReportEditorSection;
}) => {
  const reportStore = useContext(ReportStoreContext);
  const { reportApi } = useContext(ApiStoreContext);

  const [linkCopied, setLinkCopied] = useState(false);
  const [isSharingReport, setIsSharingReport] = useState(false);

  const hasReportBeenBuilt = !!report.docId;

  const isPropertiesPageShowing = !!currSection && currSection.sectionType === ReportConfigSection.Properties;

  const copyReportLink = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setLinkCopied(true);
      setTimeout(() => {
        setLinkCopied(false);
      }, 2000);
    });
  };

  const userName = user?.fullName;

  const completeReport = () => {
    if (report) {
      return reportStore.updateRemoteReport({
        name: `update report status to complete`,
        fn: () => reportApi.setStatus(report.id, ReportStatus.Complete),
      });
    }
  };

  let reportActionButton;
  if (report.status === ReportStatus.Review) {
    reportActionButton = (
      <Button
        text="Complete Report"
        icon={<CheckCircleIcon />}
        disabled={!hasReportBeenBuilt}
        onClick={() => completeReport()}
        styleClass={ButtonStyleClass.Primary}
      />
    );
  } else if (report.status !== ReportStatus.Complete) {
    reportActionButton = (
      <Button
        text="Update Report"
        icon={<WrenchScrewdriverIcon />}
        onClick={() => setIsUpdatingDoc(true)}
        styleClass={ButtonStyleClass.Primary}
        disabled={!hasReportBeenBuilt}
      />
    );
  }

  return (
    <div className="flex flex-row bg-white w-full justify-between py-3 items-center border-b border-slate-200">
      <div className="flex flex-col px-4 gap-y-2 max-w-[33%]">
        <p className="text-lg">{userName}</p>
        <div className="flex sm:flex-col md:flex-row items-center sm:gap-2 md:gap-6">
          <Button
            size="custom"
            text="Properties"
            styleClass={ButtonStyleClass.Custom}
            className={classNames(isPropertiesPageShowing ? "text-navy font-medium " : HEADER_BUTTON_STYLE_CLASSES)}
            onClick={() => showPropertiesPage()}
            icon={<CogIcon className={isPropertiesPageShowing ? "text-navy " : ""} />}
          />
          <Button
            text={linkCopied ? "Url Copied" : "Copy Url"}
            onClick={copyReportLink}
            size="custom"
            styleClass={ButtonStyleClass.Custom}
            className={HEADER_BUTTON_STYLE_CLASSES}
            icon={linkCopied ? <CheckIcon /> : <Square2StackIcon />}
          />
          <Button
            size="custom"
            text="Share"
            styleClass={ButtonStyleClass.Custom}
            onClick={() => setIsSharingReport(true)}
            className={HEADER_BUTTON_STYLE_CLASSES}
            icon={<ShareIcon />}
          />
        </div>
      </div>
      <div className="flex flex-col w-0 grow max-w-[33%]">
        <ReportProgressStepper report={report} />
      </div>
      <div className="flex flex-col gap-2 px-4">
        <div className="text-red-500 text-lg flex sm:flex-col md:flex-row items-center gap-4">
          <div className={"flex flex-row items-center gap-4"}>
            <div className={classNames(isSavingReport ? "visible" : "invisible", "flex flex-row items-center gap-2")}>
              <ArrowPathIcon className="w-5 h-5 animate-spin" />
              <span>Saving</span>
            </div>

            {hasReportBeenBuilt && (
              <div className="gap-2">
                {reportActionButton}
                <Button
                  text="View"
                  icon={<ArrowTopRightOnSquareIcon />}
                  href={`https://docs.google.com/document/d/${report.docId}/edit`}
                  styleClass={ButtonStyleClass.Custom}
                  className={HEADER_BUTTON_STYLE_CLASSES}
                />
              </div>
            )}
          </div>
        </div>
        {isSharingReport && <ShareReportModal report={report} onClose={() => setIsSharingReport(false)} />}
      </div>
    </div>
  );
};

export default observer(EditReportHeader);
