import { useState } from "react";

export const UpdateTextInput = ({
  initialValue,
  persist,
  className,
}: {
  initialValue: string;
  persist: (v: string) => void;
  className?: string;
}) => {
  const [value, setValue] = useState(initialValue);
  return (
    <input
      value={value}
      onChange={e => setValue(e.target.value)}
      onBlur={() => persist(value)}
      className={`bg-white py-1 px-2 border border-gray-200 rounded ${className}`}
    />
  );
};
