import { cloneElement, ReactElement, ReactNode, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { DocumentPlusIcon } from "@heroicons/react/24/solid";
import { Button, ButtonStyleClass } from "@/components/common/elements/button/Button";
import { classNames } from "@/utils";
import { toHeaderString } from "@/utils/assessment";
import { ReportEditorSection } from "../EditReport";

export type SidebarItemAction = {
  icon: ReactElement;
  helpText: string;
  fn: () => void;
};

export type SidebarGroupSectionItem = {
  type: ReportEditorSection;
  action?: SidebarItemAction;
};

const HEADER_ICON_STYLE_CLASSES = "h-5 w-5 text-ocean hover:text-navy";

// Common sidebar header button used for uploading sources and adding recommendations/eligibilities
export const sidebarHeaderButton = ({ onClick, label }: { onClick: () => any; label?: string }) => (
  <Button
    icon={<DocumentPlusIcon className="h-5 w-5 text-ocean hover:text-navy" />}
    onClick={onClick}
    styleClass={ButtonStyleClass.Custom}
    size="custom"
    label={label}
  />
);

const EditReportSidebarHeader = ({
  text,
  isExpanded,
  hasItems,
  isActive,
  setIsExpanded,
  children,
  headerAction,
}: {
  text: string;
  isExpanded: boolean;
  hasItems: boolean;
  isActive: boolean;
  setIsExpanded: (b: boolean) => void;
  children?: ReactNode;
  headerAction?: ReactNode;
}) => {
  const iconClasses = `${HEADER_ICON_STYLE_CLASSES} ${!hasItems && "invisible"}`;
  return (
    <div
      onClick={() => !isActive && setIsExpanded(!isExpanded)}
      className={classNames(
        "w-full h-12 p-4 text-black flex place-items-center justify-between bg-white rounded-t-md",
        isExpanded ? "" : "rounded-b-md",
        hasItems && !isActive ? "cursor-pointer" : "",
      )}
    >
      <div className="flex flex-row gap-2 items-center">
        {isExpanded ? <ChevronDownIcon className={iconClasses} /> : <ChevronRightIcon className={iconClasses} />}
        <div className="flex place-items-center gap-4">
          <span className="font-medium text-slate-900">{text}</span>
          {children}
        </div>
      </div>

      {headerAction}
    </div>
  );
};

const EditReportSidebarItem = ({
  item,
  isSelected,
  onClick,
  isLastItemInGroup,
}: {
  item: SidebarGroupSectionItem;
  isSelected: boolean;
  onClick: () => void;
  isLastItemInGroup?: boolean;
}) => {
  const buttonId = `${item.type}-action-button`;
  const action = item.action;
  return (
    <div
      onClick={onClick}
      className={classNames(
        isLastItemInGroup ? "rounded-b-md shadow-sm" : "",
        "w-full pl-10 pr-3 py-2 flex place-items-center cursor-pointer border-l-4",
        isSelected ? "bg-blue-highlight text-black border-ocean" : "hover:border-ocean bg-white border-white",
      )}
    >
      <span className="grow">{toHeaderString(item.type.sectionType)}</span>
      {!!action && (
        <>
          <button
            onClick={e => {
              e.stopPropagation();
              action.fn();
            }}
            className="p-1 text-red-secondary bg-white hover:text-white hover:bg-red-secondary outline outline-1 outline-red-secondary rounded-full"
            id={buttonId}
          >
            {cloneElement(action.icon, { className: "w-3 h-3" })}
          </button>
          <Tooltip anchorId={buttonId} content={action.helpText} place="right" />
        </>
      )}
    </div>
  );
};

const EditReportSidebarGroup = ({
  headerText,
  footerButton,
  sectionItems,
  selectedSection,
  setSelectedSection,
  headerAction,
}: {
  headerText: string;
  footerButton?: ReactNode;
  sectionItems: SidebarGroupSectionItem[];
  selectedSection?: ReportEditorSection;
  setSelectedSection: (s: ReportEditorSection) => void;
  headerAction?: ReactNode;
}) => {
  const getIsActive = () => sectionItems?.some(item => item.type.sectionType == selectedSection?.sectionType);

  const [isExpanded, setIsExpanded] = useState<boolean>(getIsActive);
  useEffect(() => {
    const isActive = getIsActive();
    isActive && setIsExpanded(isActive);
  }, [selectedSection]);

  return (
    <div className="divide-y border-slate-200">
      <EditReportSidebarHeader
        text={headerText}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        headerAction={headerAction}
        hasItems={sectionItems.length > 0}
        isActive={getIsActive()}
      />
      {isExpanded && (
        <>
          {footerButton && <div className="py-3 px-4 border-l-2">{footerButton}</div>}

          {sectionItems.map((item, idx) => (
            <div className={classNames("divide-y border-slate-200")} key={item.type.sectionType}>
              <EditReportSidebarItem
                item={item}
                isSelected={selectedSection?.sectionType === item?.type?.sectionType}
                onClick={() => setSelectedSection(item.type)}
                isLastItemInGroup={sectionItems.length - 1 === idx}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default EditReportSidebarGroup;
