import { ReactNode } from "react";

const CommonScreenLayout = ({
  headerText,
  headerControls,
  children,
}: {
  headerText: string;
  headerControls?: ReactNode;
  children: ReactNode;
}) => (
  <div className="w-full h-full py-4 flex flex-col gap-4 text-gray-900 bg-background">
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-4">
        <h1 className="text-4xl grow">{headerText}</h1>
        {headerControls}
      </div>
    </div>
    <div>{children}</div>
  </div>
);

export default CommonScreenLayout;
