import { useEffect, useRef, useState } from "react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { classNames } from "@/utils";
import AutoCompleteResults, { AutoCompleteSearchProps } from "./AutoCompleteResults";

export type AutoCompleteSelectProps<A> = Omit<
  AutoCompleteSearchProps<A>,
  "searchValue" | "isFocused" | "setIsFocused"
> & {
  onShowToggle?: (isFocused: boolean) => void;
  focusOnMount?: boolean;
  placeholder?: string;
  containerClassName?: string;
  inputClassName?: string;
};

// TODO make tab-navigatable
const AutoCompleteSelect = <A,>(props: AutoCompleteSelectProps<A>) => {
  const { onShowToggle = () => null, focusOnMount = false, placeholder, containerClassName, inputClassName } = props;

  const [isFocused, setIsFocused] = useState(focusOnMount);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    onShowToggle(isFocused);
  }, [isFocused]);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    focusOnMount && inputRef.current?.focus();
  }, [inputRef.current]);

  return (
    <div className={classNames("relative w-full py-2 px-4 bg-white rounded-md", containerClassName)}>
      <div
        className={classNames(
          "w-full h-full flex flex-row gap-2 items-center",
          isFocused ? `text-black ${searchValue ? "border-0" : "border-black"}` : "text-gray-400 border-gray-300",
          inputClassName,
        )}
      >
        <MagnifyingGlassIcon className="h-5 w-5" />
        <input
          type="text"
          value={searchValue}
          placeholder={placeholder}
          onChange={e => setSearchValue(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className="grow border-0 focus:ring-0"
          ref={inputRef}
        />
        <XMarkIcon className="h-5 w-5 cursor-pointer" onClick={() => setSearchValue("")} />
      </div>
      <AutoCompleteResults searchValue={searchValue} isFocused={isFocused} setIsFocused={setIsFocused} {...props} />
    </div>
  );
};

export default AutoCompleteSelect;
