import { useContext } from "react";
import { observer } from "mobx-react-lite";
import Modal from "@/components/common/content/Modal";
import { AlertStoreContext } from "@/store";
import LoginForm from "./common/content/LoginForm";

const GlobalFailureModal = () => {
  const alertStore = useContext(AlertStoreContext);
  const { failedProcessName, isSessionExpired } = alertStore;

  if (!isSessionExpired && !failedProcessName) return <></>;

  if (isSessionExpired)
    return (
      <Modal title="Session Expired">
        <div className="flex flex-col gap-4 place-items-center">
          <p>Your sign-in session has expired. To continue using the app, please sign in again.</p>
          <LoginForm onSuccess={() => alertStore.clear()} />
        </div>
      </Modal>
    );

  return (
    <Modal title="Processing Error" onClose={() => alertStore.clear()}>
      <p className="mb-4">There was an unexpected error while attempting to {failedProcessName}.</p>
      <p>
        Please close this modal and try again / refresh the page - if the issue persists, reach out to tech support.
      </p>
    </Modal>
  );
};

export default observer(GlobalFailureModal);
