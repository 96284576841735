import { useContext } from "react";
import { observer } from "mobx-react-lite";
import sanitize from "sanitize-html";
import { HydratedEligibility } from "@parallel/vertex/types/report.guidance.types";
import { AssessmentReport, ReportEligibility } from "@parallel/vertex/types/report.types";
import { getTokenRegex } from "@parallel/vertex/util/report.util";
import { getCommaList } from "@parallel/vertex/util/string.util";
import MultiSelectWindow from "@/components/common/windows/MultiSelectWindow";
import { ApiStoreContext, ReportStoreContext } from "@/store";
import { classNames } from "@/utils";

const EligibilityConfigPanel = ({
  eligibility,
  report,
  isLocked,
}: {
  report: AssessmentReport;
  eligibility: HydratedEligibility;
  isLocked: boolean;
}) => {
  const { reportGuidanceApi } = useContext(ApiStoreContext);
  const reportStore = useContext(ReportStoreContext);
  const { availableEligibilityCriteriaItems } = reportStore;

  const reportEligibility = report.eligibilities?.find(d => d.eligibilityId === eligibility.id);
  if (!reportEligibility)
    return (
      <div className="text-xl w-full h-full flex items-center justify-center">
        The selected eligibility does not exist
      </div>
    );

  const availableCriteria = availableEligibilityCriteriaItems[eligibility.id] || [];

  const updateReportEligibility = (name: string, update: Partial<ReportEligibility>) =>
    reportStore.updateRemoteReport({
      name,
      fn: () => reportGuidanceApi.upsertEligibility(report.id, eligibility.id, update).then(r => r.report),
    });

  const descriptionContent = availableCriteria.reduce((currDescription, nextCriteriaSet) => {
    const tokenRegex = getTokenRegex(nextCriteriaSet.token);
    const match = currDescription.match(tokenRegex);
    if (!match) return currDescription;
    const enabledOptions = nextCriteriaSet.items.filter(i => i.isEnabled).map(i => i.name);
    const replaceContent = enabledOptions.length === 0 ? match[0] : getCommaList(enabledOptions);
    return currDescription.replace(tokenRegex, `<span class="font-bold">${replaceContent}</span>`);
  }, sanitize(eligibility.descriptionTemplate));

  return (
    <div className="p-4 flex flex-col gap-6">
      {availableCriteria.map((cl, i) => (
        <>
          <div
            className={classNames("mb-2 font-semithin text-md flex flex-row place-items-center gap-2 text-gray-700")}
          >
            <span className="text-lg">{cl.token} Options</span>
          </div>
          <div key={i} className="border-t border-b border-slate-300">
            <MultiSelectWindow
              items={cl.items}
              setEnabledItems={
                isLocked
                  ? undefined
                  : thisListEnabledIds => {
                      const enabledCriteriaIds = availableCriteria.flatMap((cl2, i2) =>
                        i2 === i ? thisListEnabledIds : cl2.items.filter(i => i.isEnabled).map(i => i.key),
                      );
                      updateReportEligibility("update eligibility criteria", { enabledCriteriaIds });
                    }
              }
            />
          </div>
        </>
      ))}

      <div>
        <div className="flex flex-col">
          <p className="font-medium text-lg">Report Output</p>
          <p className="pb-8 text-gray-700 italic">This text will appear in the final report</p>
        </div>

        <div
          className="w-[95%] p-4 border border-slate-200 rounded-md"
          dangerouslySetInnerHTML={{ __html: descriptionContent }}
        />
      </div>
    </div>
  );
};

export default observer(EligibilityConfigPanel);
