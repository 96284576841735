import { StrictMode, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import config from "@/config";
import App from "./App";
import "./main.css";

const isProd = config.parallelEnv === "production";

Sentry.init({
  dsn: config.sentryEndpoint,
  environment: config.parallelEnv,
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: isProd,
      blockAllMedia: isProd,
    }),
  ],
  profilesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: isProd ? 0.1 : 1.0,
  tracesSampleRate: isProd ? 0.25 : 1.0,
});

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

(async () => {
  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
  root.render(
    <StrictMode>
      <Sentry.ErrorBoundary fallback={<FallbackComponent />} showDialog>
        <GoogleOAuthProvider clientId={config.oauthClientId}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </GoogleOAuthProvider>
      </Sentry.ErrorBoundary>
    </StrictMode>,
  );
})();
