export const Checkbox = ({
  id,
  label,
  isChecked,
  toggle,
}: {
  id: string;
  label: string;
  isChecked: boolean;
  toggle: () => void;
}) => {
  return (
    <div className="flex flex-row gap-2 items-center cursor-pointer">
      <input
        id={id}
        type="checkbox"
        className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
        checked={isChecked}
        onChange={toggle}
      />
      <label htmlFor={id} className="block text-sm text-gray-900">
        {label}
      </label>
    </div>
  );
};
