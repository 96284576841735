import { ReactNode } from "react";

export const Link = ({
  href,
  newTab = true,
  onClick,
  className = "text-ocean underline cursor-pointer",
  children,
}: {
  href?: string;
  newTab?: boolean;
  onClick?: () => void;
  className?: string;
  children: ReactNode;
}) => (
  <a
    className={className}
    target={newTab ? "_blank" : ""}
    rel={newTab ? "noopener noreferrer" : ""}
    href={href}
    onClick={onClick}
  >
    {children}
  </a>
);
