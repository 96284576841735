import Modal from "@/components/common/content/Modal";

export type ConfirmSpec = {
  title: string;
  prompt: string;
  submitButtonText?: string;
  closeButtonText?: string;
};
export type ConfirmAction = {
  action: () => void;
  name: string;
  spec: ConfirmSpec;
};

const ConfirmationModal = ({ action, spec, onClose }: ConfirmAction & { onClose: () => void }) => (
  <Modal
    title={spec.title}
    onClose={onClose}
    onSubmit={() => {
      action();
      onClose();
    }}
    submitButtonText={spec.submitButtonText || "Yes"}
    containerStyle={{ maxWidth: "50%" }}
  >
    <p>{spec.prompt}</p>
  </Modal>
);

export default ConfirmationModal;
