import { ReactElement, useContext } from "react";
import { ArrowPathIcon, ArrowUturnLeftIcon, MinusIcon } from "@heroicons/react/24/outline";
import { observer } from "mobx-react-lite";
import { AllReportValueGroups, isUpload, ReportValueGroup } from "@parallel/vertex/enums/report.enums";
import { AssessmentReport } from "@parallel/vertex/types/report.types";
import { ReportAPI } from "@/api/report.api";
import { ConfirmSpec } from "@/components/common/content/ConfirmModal";
import { ApiStoreContext, ReportStoreContext } from "@/store";
import { ReportStore } from "@/store/report.store";
import { hasInjectedValues, hasPendingValues, hasValues, toHeaderString } from "@/utils/assessment";
import { ReportEditorSection } from "../EditReport";
import EditReportSidebarGroup, { SidebarGroupSectionItem, sidebarHeaderButton } from "./EditReportSidebarGroup";

export enum ValueGroupActionType {
  Reset = "reset",
  Clear = "clear",
  Revert = "revert",
}
type ValueGroupAction = {
  icon: ReactElement;
  helpText: string;
  getConfirmSpec?: (g: ReportValueGroup) => ConfirmSpec;
};
export const ValueGroupActions: Record<ValueGroupActionType, ValueGroupAction> = {
  [ValueGroupActionType.Reset]: {
    icon: <ArrowPathIcon />,
    helpText: "Reset With Latest Values",
  },
  [ValueGroupActionType.Clear]: {
    icon: <MinusIcon />,
    helpText: "Clear Group Data",
    getConfirmSpec: g => ({
      title: "Clear Group Data",
      prompt: `All data in the group ${toHeaderString(g)} will be removed. Are you sure you want to continue?`,
    }),
  },
  [ValueGroupActionType.Revert]: {
    icon: <ArrowUturnLeftIcon />,
    helpText: "Remove Pending Group Data",
    getConfirmSpec: g => ({
      title: "Remove Pending GroupData",
      prompt: `All pending data uploaded to group ${toHeaderString(
        g,
      )} since the latest build will be removed. Are you sure you want to continue?`,
    }),
  },
};

const getActionType = (group: ReportValueGroup, report: AssessmentReport) => {
  if (isUpload(group)) {
    if (!hasPendingValues(report, group)) return;
    else if (!hasInjectedValues(report, group)) return ValueGroupActionType.Clear;
    else return ValueGroupActionType.Revert;
  }
  return ValueGroupActionType.Reset;
};

export const getValueGroupAction = (
  reportStore: ReportStore,
  reportApi: ReportAPI,
  group: ReportValueGroup,
  report: AssessmentReport,
  isGroupSelected: boolean,
  moveSelection: (delta: number) => void,
) => {
  const actionType = getActionType(group, report);
  if (!actionType) return;
  const { icon, helpText, getConfirmSpec } = ValueGroupActions[actionType];

  return {
    icon,
    helpText,
    fn: () =>
      reportStore.updateRemoteReport({
        name: `${helpText.toLowerCase()} of group ${group}`,
        fn: () => reportApi.resetSourceGroup(report.id, group),
        sideEffect: updatedReport => {
          if (isGroupSelected || hasValues(updatedReport, group)) return;
          moveSelection(1);
        },
        confirmSpec: getConfirmSpec && getConfirmSpec(group),
      }),
  };
};

const EditReportSidebarUploadGroup = observer(
  ({
    report,
    selectedSection,
    setSelectedSection,
    setIsUploading,
    moveSelection,
  }: {
    report: AssessmentReport;
    selectedSection?: ReportEditorSection;
    setSelectedSection: (s: ReportEditorSection) => void;
    setIsUploading: (b: boolean) => void;
    moveSelection: (delta: number) => void;
  }) => {
    const { reportApi } = useContext(ApiStoreContext);
    const reportStore = useContext(ReportStoreContext);
    const dataSourceItems: SidebarGroupSectionItem[] = AllReportValueGroups.filter(
      g => isUpload(g) && hasValues(report, g),
    ).map(group => ({
      type: { sectionType: group, subType: "ReportValueGroup" },
      action: getValueGroupAction(
        reportStore,
        reportApi,
        group,
        report,
        group === selectedSection?.sectionType,
        moveSelection,
      ),
    }));

    const uploadButton = sidebarHeaderButton({ onClick: () => setIsUploading(true), label: "upload-scores" });

    return (
      <EditReportSidebarGroup
        headerText="Uploaded Data"
        headerAction={uploadButton}
        sectionItems={dataSourceItems}
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
      />
    );
  },
);

export default EditReportSidebarUploadGroup;
