import { AuthorizedUser } from "@parallel/vertex/types/auth.types";
import { BaseAPI } from "./base.api";

export class AuthAPI extends BaseAPI {
  public googleSignIn = (token: string): Promise<AuthorizedUser> =>
    this.instance.post("auth/google", { token }).then(r => r.data);

  public restoreSignIn = (): Promise<AuthorizedUser> =>
    this.instance
      .post("auth/restore")
      .then(r => r.data)
      .catch(e => {
        if (e.response?.status === 404) return null;
        else throw e;
      });

  public signOut = () => this.instance.delete("auth");
}
