import { ReactNode, useEffect, useRef, useState } from "react";
import {
  CheckCircleIcon,
  PencilSquareIcon,
  UserGroupIcon,
  ChevronRightIcon,
  ChatBubbleLeftEllipsisIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/20/solid";
import { GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams } from "@mui/x-data-grid";
import { format } from "fecha";
import { HydratedAssessmentReport } from "@parallel/vertex/types/report.types";
import { allReportReviewers } from "@parallel/vertex/util/report.util";
import Collapsable from "@/components/common/elements/display/Collapsable";
import { ClientPaginatedTable } from "@/components/common/table/ClientPaginatedTable";
import config from "@/config";
import { loggerContext } from "@/store";
import { initLogger } from "@/utils";
import { useLoggedRedirect } from "@/utils/redirect.utils";

const logger = initLogger("AssessmentReportList", loggerContext);

// TODO: Update this to use ReportStatus during redesign process
export enum AssessmentListType {
  ScoreEntry = "Score-Entry",
  Interpretation = "Interpretation",
  Review = "Review",
  Complete = "Complete",
  // These will be phased out once the stage redesign is fully rolled out
  InProgress = "In-Progress",
  SharedWithMe = "Shared-With-Me",
}

type HeaderContent = {
  title: string;
  icon: ReactNode;
};

const headerIconStyle = "space-x-4 h-6 mt-0.5 w-6 mr-1.5";
const headerContent: Record<AssessmentListType, HeaderContent> = {
  [AssessmentListType.ScoreEntry]: {
    title: "In Progress - Test Administration & Score Entry",
    icon: <PencilSquareIcon className={`${headerIconStyle} fill-navy`} />,
  },
  [AssessmentListType.Interpretation]: {
    title: "In Progress - Interpretation",
    icon: <ChatBubbleLeftEllipsisIcon className={`${headerIconStyle} fill-navy`} />,
  },
  [AssessmentListType.Review]: {
    title: "Team Lead Review",
    icon: <ClipboardDocumentCheckIcon className={`${headerIconStyle} fill-pink`} />,
  },
  [AssessmentListType.Complete]: {
    title: "Completed",
    icon: <CheckCircleIcon className={`${headerIconStyle} fill-mint-dark`} />,
  },
  [AssessmentListType.InProgress]: {
    title: "In Progress",
    icon: <PencilSquareIcon className={`${headerIconStyle} fill-orange`} />,
  },
  [AssessmentListType.SharedWithMe]: {
    title: "Shared With Me",
    icon: <UserGroupIcon className={`${headerIconStyle} fill-ocean`} />,
  },
};

export const AssessmentReportList = ({
  reports,
  listType,
}: {
  reports: HydratedAssessmentReport[];
  listType: AssessmentListType;
}) => {
  const redirect = useLoggedRedirect(logger);

  const [listContainerHeight, setListContainerHeight] = useState<number>();

  const listContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!listContainerRef.current) return;

    // ref changes do not trigger component re-renders, which can cause
    // issues with the search filters. using a `ResizeObserver` solves
    // the issue
    const resizeObserver = new ResizeObserver(() => {
      if (listContainerRef.current?.clientHeight !== listContainerHeight)
        setListContainerHeight(listContainerRef.current?.clientHeight);
    });

    resizeObserver.observe(listContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [listContainerRef.current]);

  const { title, icon } = headerContent[listType];

  const dateValueFormatter = (params: GridValueFormatterParams<Date>) =>
    params.value ? format(params.value, "MMM D, YYYY") : "";

  const reportColumns = [
    { field: "id", headerName: "Id" },
    {
      field: "client",
      headerName: "CLIENT",
      flex: 1.3,
      renderCell: (params: GridRenderCellParams) => (
        <div className="flex flex-col text-base">
          <span>{params.row.client.name}</span>
        </div>
      ),
      valueGetter: (params: GridValueGetterParams) => params.value.name,
    },
    {
      field: "startedAt",
      headerName: "STARTED",
      fieldType: "date",
      valueFormatter: dateValueFormatter,
      flex: 0.7,
    },
    {
      field: "updatedAt",
      headerName: "UPDATED",
      fieldType: "date",
      valueFormatter: dateValueFormatter,
      flex: 0.7,
    },
    {
      field: "dueDate",
      headerName: "DUE DATE",
      fieldType: "date",
      valueFormatter: dateValueFormatter,
      flex: 0.7,
    },
    {
      field: "reviewer",
      headerName: "REVIEWER",
      flex: 1,
    },
    {
      field: "reportType",
      headerName: "REPORT TYPE",
      flex: 1,
    },
    {
      field: "link",
      headerName: "",
      sortable: false,
      flex: 0.3,
      renderCell: () => (
        <div className="grow cursor-pointer">
          <ChevronRightIcon className="float-right h-7 w-7" />
        </div>
      ),
    },
  ];

  const constructReportRows = () => {
    const rows = reports.map(({ report, user }) => ({
      id: report?.id,
      client: { name: user?.fullName, userId: report.clientUserId },
      startedAt: new Date(report.createdAt.isoString),
      updatedAt:
        report.statusHistory && report.statusHistory.length > 0
          ? new Date(report.statusHistory[0].updatedAt.isoString)
          : null,
      dueDate: report.dueDate ? new Date(report.dueDate.isoString) : null,
      reviewer: allReportReviewers(config).find(reviewer => report.reviewerUserId === reviewer.userId)?.name || "",
      reportType: report.type,
    }));
    return rows;
  };

  return (
    <Collapsable title={title} icon={icon} showInitially={listType === AssessmentListType.InProgress}>
      <div
        className="flex flex-col max-h-[580px] bg-white text-sm cursor-pointer divide-y divide-gray-200"
        ref={listContainerRef}
        style={{
          height: listContainerHeight && `${listContainerHeight}px`,
        }}
      >
        <ClientPaginatedTable
          gridStyles={{
            "&.MuiDataGrid-root": {
              border: "none",
            },
            ".MuiDataGrid-columnHeaders, .MuiDataGrid-sortIcon": {
              color: "#086de2",
            },
            ".MuiDataGrid-columnHeader--sorted .MuiDataGrid-columnHeaderTitle": {
              "font-weight": "bold",
            },
          }}
          columns={reportColumns}
          rows={constructReportRows() || []}
          rowHeight={64}
          hideToolbar={true}
          onRowClick={(row: any) => redirect(`/assessment/report/${row.id}`, "report row clicked")}
          pageSize={100}
          checkboxSelection={false}
          disableColumnFilters={true}
          enableFuzzySearch={false}
        />
      </div>
    </Collapsable>
  );
};
