import { initServiceConfig } from "@parallel/vertex/config/initialize.config";

const config = initServiceConfig(import.meta.env, {
  global: {
    sentryEndpoint: "https://9c44014b6d40454dbd26987e52b264b0@o1183338.ingest.sentry.io/4504198668156928",
  },
  local: {
    nexusServerUrl: "http://localhost:8082",
  },
  preprod: {
    nexusServerUrl: "https://api.parallel-staging.com/org",
    featherySdkKey: "091da07c-b1b7-40c2-b718-cd1b0dbdadae",
  },
  prod: {
    nexusServerUrl: "https://api.parallellearning.com/org",
    featherySdkKey: "f9c2c973-e236-445d-a8c6-db33a8434226",
  },
});

export default config;
