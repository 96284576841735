import { ReactNode, useState } from "react";
import { ChevronDownIcon, ChevronRightIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { classNames } from "@/utils";
import ClickableDiv from "../button/ClickableDiv";

const Collapsable = ({
  title,
  icon,
  headerControls,
  isWarning,
  subheader,
  showInitially = false,
  useLeftChevron = false,
  children,
}: {
  title: string;
  icon?: ReactNode;
  headerControls?: ReactNode;
  isWarning?: boolean;
  subheader?: ReactNode;
  showInitially?: boolean;
  useLeftChevron?: boolean;
  children: ReactNode;
}) => {
  const [isEnabled, setIsEnabled] = useState<boolean>();
  const isShowing = isEnabled === undefined ? showInitially : isEnabled;

  const chevronIcon = isShowing ? <ChevronDownIcon className="h-6 w-6" /> : <ChevronRightIcon className="h-6 w-6" />;
  const headerIcon = isWarning ? <ExclamationTriangleIcon className="w-6 h-6 mt-1" /> : icon;
  return (
    <div className="flex flex-col rounded border bg-white border-gray-300" role="region">
      <div
        className={classNames(
          "bg-gray-50 border-gray-300",
          isShowing ? "border-b border-gray-300 rounded-t" : "rounded",
          isWarning ? "text-orange" : "",
        )}
      >
        <ClickableDiv
          onClick={() => setIsEnabled(!isShowing)}
          className="flex flex-row p-4 justify-between text-xl cursor-pointer"
          role="button"
        >
          <div className="flex gap-4 place-items-center">
            {useLeftChevron && chevronIcon}
            {headerIcon}
            <span>{title}</span>
          </div>
          <div className="flex flex-row place-items-center gap-6">
            {headerControls}
            {!useLeftChevron && chevronIcon}
          </div>
        </ClickableDiv>
        {isShowing && subheader}
      </div>
      {isShowing && children}
    </div>
  );
};

export default Collapsable;
