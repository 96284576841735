import { useContext } from "react";
import { ArrowDownOnSquareIcon, DocumentPlusIcon } from "@heroicons/react/24/outline";
import Modal from "@/components/common/content/Modal";
import { ConfirmButton } from "@/components/common/elements/button/Button";
import { ReportStoreContext } from "@/store";

const UpdateDocModal = ({ onClose }: { onClose: () => void }) => {
  const reportStore = useContext(ReportStoreContext);

  const inject = () => {
    onClose();
    reportStore.reinjectValuesIncrementally();
  };
  const rebuild = () => {
    onClose();
    reportStore.buildIncrementally({ isRebuild: true });
  };

  return (
    <Modal title="Update Doc" onClose={onClose} containerStyle={{ maxWidth: "75%" }}>
      <div className="flex flex-col gap-6">
        <div>
          <ConfirmButton text="Inject Values" icon={<ArrowDownOnSquareIcon className="w-6 h-6" />} onClick={inject} />
          <p className="mt-2 text-gray-600 italic">
            Non-destructively adds newly-uploaded data values to the existing document and updates the eligibility and
            recommendations sections.
            <br />
            Does not update any data that was formerly injected.
          </p>
        </div>
        <div>
          <ConfirmButton text="Build New Doc" icon={<DocumentPlusIcon className="w-6 h-6" />} onClick={rebuild} />
          <p className="mt-2 text-gray-600 italic">
            Replace the current document with an entirely new document injected with the currently-selected data,
            eligibilities, and recommendations.
            <br />
            Any manual edits made in the current file will be lost.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateDocModal;
