import { useContext } from "react";
import { EyeIcon, EyeSlashIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { useMountEffect } from "@react-hookz/web";
import { observer } from "mobx-react-lite";
import { mapExists } from "@parallel/vertex/util/collection.util";
import { Button } from "@/components/common/elements/button/Button";
import CommonScreenLayout from "@/components/common/windows/OldCommonScreenLayout";
import { StimulusStoreContext, loggerContext } from "@/store";
import { classNames, initLogger } from "@/utils";
import { useLoggedRedirect } from "@/utils/redirect.utils";

const logger = initLogger("StimulusHomeScreen", loggerContext);

const StimulusHomeScreen = () => {
  const stimulusStore = useContext(StimulusStoreContext);

  const redirect = useLoggedRedirect(logger);

  useMountEffect(() => {
    logger.mount("StimulusHomeScreen");
    stimulusStore.fetchRawBooks();
  });

  const eyeIconClass = (hidden: boolean) =>
    classNames("w-5 h-5 hover:text-ocean cursor-pointer", hidden ? "text-gray-300" : "");

  return (
    <CommonScreenLayout
      headerText="Assessment Stimulus Editor"
      headerControls={
        <Button
          text="Upload Stimulus"
          icon={<PlusCircleIcon />}
          onClick={() => redirect("/stimulus/upload", "upload book click")}
        />
      }
    >
      <div className="w-full h-full flex flex-col gap-2">
        <h2 className="text-xl">Test Books</h2>
        <div className="w-full flex flex-col items-start overflow-y-auto bg-white divide-y border border-gray-300 rounded-md">
          {mapExists(stimulusStore.books, b => (
            <div className="w-full h-10 px-2 flex flex-row place-items-center" key={b.id}>
              <div
                onClick={() => redirect(`/stimulus/book/${b.id}/edit`, "edit book click")}
                className="h-full py-2 grow hover:font-bold hover:text-ocean cursor-pointer"
              >
                {b.name}
              </div>
              {b.hidden ? (
                <EyeSlashIcon onClick={() => stimulusStore.setBookHidden(b.id, false)} className={eyeIconClass(true)} />
              ) : (
                <EyeIcon onClick={() => stimulusStore.setBookHidden(b.id, true)} className={eyeIconClass(false)} />
              )}
            </div>
          ))}
        </div>
      </div>
    </CommonScreenLayout>
  );
};

export default observer(StimulusHomeScreen);
