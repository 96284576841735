import { AuthAPI } from "@/api/auth.api";
import { FormAPI } from "@/api/form.api";
import { ReportAPI } from "@/api/report.api";
import { ReportDocumentAPI } from "@/api/report.document.api";
import { ReportGuidanceAPI } from "@/api/report.guidance.api";
import { StimulusAPI } from "@/api/stimulus.api";
import { UserAPI } from "@/api/user.api";
import { AlertStore } from "./alert.store";
import { UserStore } from "./user.store";

export class ApiStore {
  authApi: AuthAPI;
  formApi: FormAPI;
  reportApi: ReportAPI;
  reportDocumentApi: ReportDocumentAPI;
  reportGuidanceApi: ReportGuidanceAPI;
  stimulusApi: StimulusAPI;
  userApi: UserAPI;

  constructor(alertStore: AlertStore, userStore: UserStore) {
    this.authApi = new AuthAPI();
    this.formApi = new FormAPI(alertStore, userStore);
    this.reportApi = new ReportAPI(alertStore, userStore);
    this.reportDocumentApi = new ReportDocumentAPI(alertStore, userStore);
    this.reportGuidanceApi = new ReportGuidanceAPI(alertStore, userStore);
    this.stimulusApi = new StimulusAPI(alertStore, userStore);
    this.userApi = new UserAPI(alertStore, userStore);
  }
}
