import { useContext, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { keyBy, mapValues } from "lodash";
import { AssessmentReport, OptionalSection } from "@parallel/vertex/types/report.types";
import { Button, ButtonStyleClass } from "@/components/common/elements/button/Button";
import MultiSelectWindow, { SelectItem } from "@/components/common/windows/MultiSelectWindow";
import { ApiStoreContext, ReportStoreContext } from "@/store";
import { classNames } from "@/utils";
import { SubHeaderValidationItem } from "../header/SubHeaderControls";
import ConfigWindowLayout from "./ConfigWindowLayout";

const SectionsConfig = ({ report }: { report: AssessmentReport }) => {
  const { reportApi } = useContext(ApiStoreContext);
  const reportStore = useContext(ReportStoreContext);
  const { areTestInterpretationsValid } = reportStore;

  const hasReportBeenBuilt = !!report.docId;

  const sections = report.testInterpretations || [];
  const [sectionOpen, setSectionOpen] = useState(mapValues(keyBy(sections, "name"), () => false));

  const updateSection = (updatedSection: OptionalSection) => {
    const updatedSections = sections.map(s => (s.name === updatedSection.name ? updatedSection : s));
    reportStore.updateRemoteReport({
      name: "update test interpretations",
      fn: () => reportApi.updateReport(report.id, { testInterpretations: updatedSections }),
      updateLocalConfig: true,
    });
  };

  const setSubsectionsEnabled = (section: OptionalSection, resolveEnabled: (s: OptionalSection) => boolean) => {
    if (hasReportBeenBuilt) return;
    const updatedSubsections = (section.subsections || []).map(s => ({ ...s, isEnabled: resolveEnabled(s) }));
    updateSection({ ...section, subsections: updatedSubsections });
  };

  const iconClassName = "h-5 w-5 mr-4";

  return (
    <ConfigWindowLayout
      title="Sections To Include"
      subtitle="These selections will determine which sections are included in the report"
      headerControls={
        <SubHeaderValidationItem text="Select at least one section" isValid={areTestInterpretationsValid} />
      }
      isSectionLocked={hasReportBeenBuilt}
    >
      <div className="w-full flex flex-col ">
        {sections.map((section, i) => {
          const isOpen = sectionOpen[section.name];
          const children = section.subsections || [];
          const childrenCount = children.length;
          const enabledChildrenCount = children.filter(s => s.isEnabled).length;
          const isSelectGroup = childrenCount > 0;

          return (
            <div className="pb-4" key={i}>
              {!isSelectGroup ? (
                <SelectItem
                  item={{ ...section, key: section.name }}
                  onClick={
                    !hasReportBeenBuilt ? () => updateSection({ ...section, isEnabled: !section.isEnabled }) : undefined
                  }
                  containerClassName="border border-gray-300 rounded-md"
                  canUpdate={!hasReportBeenBuilt}
                />
              ) : (
                <div className="rounded-md bg-white border border-gray-300 cursor-pointer">
                  <div
                    className={classNames(
                      isSelectGroup && isOpen ? "border-slate-300 border-b" : "",
                      "p-4 flex flex-row text-lg font-semibold place-items-center",
                    )}
                    onClick={() => setSectionOpen({ ...sectionOpen, [section.name]: !isOpen })}
                  >
                    <ChevronDownIcon className={iconClassName} />

                    <span className="grow">{section.name}</span>
                    <div className="flex flex-row items-center">
                      {isSelectGroup && (
                        <div className="flex sm:flex-col md:flex-row text-sm font-normal items-center gap-4">
                          <div className="italic text-slate-700 sm:invisible md:visible">
                            {enabledChildrenCount} of {childrenCount} Selected
                          </div>

                          {!hasReportBeenBuilt && (
                            <>
                              <div>
                                <Button
                                  size="custom"
                                  text="Select all"
                                  styleClass={ButtonStyleClass.Custom}
                                  className={classNames("text-ocean hover:text-navy")}
                                  onClick={() => {
                                    setSubsectionsEnabled(section, () => true);
                                  }}
                                />
                              </div>

                              <div>
                                <Button
                                  size="custom"
                                  text="Select none"
                                  styleClass={ButtonStyleClass.Custom}
                                  className={classNames("text-ocean hover:text-navy")}
                                  onClick={() => {
                                    setSubsectionsEnabled(section, () => false);
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {isOpen && (
                    <MultiSelectWindow
                      items={children.map(s => ({ ...s, key: s.name }))}
                      isSectionLocked={hasReportBeenBuilt}
                      setEnabledItems={
                        !hasReportBeenBuilt
                          ? selected => setSubsectionsEnabled(section, s => selected.includes(s.name))
                          : undefined
                      }
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </ConfigWindowLayout>
  );
};

export default SectionsConfig;
