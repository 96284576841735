import { ReactNode } from "react";
import { LockClosedIcon } from "@heroicons/react/24/solid";

const ConfigWindowLayout = ({
  title,
  headerControls,
  subtitle,
  children,
  isSectionLocked,
}: {
  title: string;
  subtitle?: string;
  headerControls?: ReactNode;
  children: ReactNode;
  isSectionLocked?: boolean;
}) => {
  return (
    <div className="w-full h-full flex flex-col bg-neutral">
      <div className="p-4 w-full flex flex-row gap-2 items-center justify-between border-b border-gray-200 min-h-20 bg-white">
        <div className="flex flex-col justify-center">
          <div className="flex flex-row gap-2 items-center">
            {isSectionLocked && <LockClosedIcon className="w-5 h-5 text-slate-900" />}
            <h2 className="text-xl">{title}</h2>
          </div>

          {subtitle && <h4 className="text-md italic">{subtitle}</h4>}
        </div>

        {headerControls}
      </div>
      <div className="w-full h-full overflow-y-auto flex max-w-7xl justify-center mx-auto p-4 bg-white">{children}</div>
    </div>
  );
};

export default ConfigWindowLayout;
