import { action } from "mobx";
import { NexusBaseAPI } from "@parallel/vertex/util/nexus.base.api";
import config from "@/config";
import { AlertStore } from "@/store/alert.store";
import { UserStore } from "@/store/user.store";

export class BaseAPI extends NexusBaseAPI {
  public constructor() {
    super({ baseURL: config.nexusServerUrl });
  }
}

export class AuthorizedBaseAPI extends BaseAPI {
  constructor(alertStore: AlertStore, userStore: UserStore) {
    super();
    this.getAuthKey = () => userStore.currentUser?.accessToken;
    this.handleAuthFailure = action(() => (alertStore.isSessionExpired = true));
  }
}
