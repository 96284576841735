import { useContext, useState } from "react";
import { TrashIcon, XCircleIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import { partition, uniqBy } from "lodash";
import { AllValueFileSources, ValueFileSource } from "@parallel/vertex/enums/report.enums";
import { AssessmentReport } from "@parallel/vertex/types/report.types";
import { AssessmentReportSourceFile } from "@/api/report.api";
import Modal from "@/components/common/content/Modal";
import { Button, DEFAULT_BUTTON_CLASS } from "@/components/common/elements/button/Button";
import FileInput from "@/components/common/elements/input/FileInput";
import Select from "@/components/common/elements/select/Select";
import { ApiStoreContext, ReportStoreContext, loggerContext } from "@/store";
import { classNames, initLogger } from "@/utils";

type FileState = {
  file: File;
  source?: string;
};

const FileSourceOptions = AllValueFileSources.map(value => ({
  value,
  name: value,
}));

const guessFileSource = (file: File): ValueFileSource | undefined => {
  const fileName = file.name.toLowerCase();
  if (["wj", "woodcock", "johnson", ".html"].find(m => fileName.includes(m))) {
    return ValueFileSource.WoodcockJohnson;
  } else if (fileName.includes("wisc")) {
    return ValueFileSource.WISC;
  } else if (fileName.includes("basc")) {
    if (fileName.includes("parent")) return ValueFileSource.BASC_Parent;
    if (fileName.includes("self")) return ValueFileSource.BASC_Self;
    if (fileName.includes("teacher")) return ValueFileSource.BASC_Teacher;
    if (fileName.includes("6-7") || fileName.includes("child") || fileName.includes("interview"))
      return ValueFileSource.BASC_Child_Interview;
  } else if (fileName.includes("brief")) {
    if (fileName.includes("parent")) return ValueFileSource.BRIEF_Parent;
    if (fileName.includes("self")) return ValueFileSource.BRIEF_Self;
    if (fileName.includes("teacher")) return ValueFileSource.BRIEF_Teacher;
  } else if (fileName.includes("celf")) {
    if (["5-", "8"].some(s => fileName.includes(s))) return ValueFileSource.CELF_5_8;
    if (["9", "21"].some(s => fileName.includes(s))) return ValueFileSource.CELF_9_21;
    if (fileName.includes("pre")) return ValueFileSource.CELF_Preschool;
    if (fileName.includes("meta")) return ValueFileSource.CELF_Meta;
  } else if (fileName.includes("evt")) {
    return ValueFileSource.EVT_3;
  } else if (fileName.includes("ppvt")) {
    return ValueFileSource.PPVT_5;
  } else if (fileName.includes("diva")) {
    if (fileName.includes("young")) return ValueFileSource.Young_DIVA;
    else return ValueFileSource.DIVA;
  } else if (fileName.includes("gfta") || fileName.includes("klpa")) {
    return ValueFileSource.GFTA_KLPA;
  } else if (fileName.includes("iva")) {
    if (fileName.includes("standard")) return ValueFileSource.IVA_Standard;
    if (fileName.includes("sustained")) return ValueFileSource.IVA_Sustained;
  } else if (fileName.includes("vineland")) {
    if (fileName.includes("parent")) return ValueFileSource.Vineland_Parent;
    if (fileName.includes("teacher")) return ValueFileSource.Vineland_Teacher;
  } else if (fileName.includes("srs")) {
    if (fileName.includes("parent")) return ValueFileSource.SRS_Parent;
    if (fileName.includes("teacher")) return ValueFileSource.SRS_Teacher;
  } else if (fileName.includes("wiat")) {
    return ValueFileSource.WIAT;
  }
  return;
};

const logger = initLogger("UploadSourceFilesModal", loggerContext);

const UploadSourceFilesModal = ({ report, onClose }: { report: AssessmentReport; onClose: () => void }) => {
  const { reportApi } = useContext(ApiStoreContext);
  const reportStore = useContext(ReportStoreContext);

  const [selectedFiles, setSelectedFiles] = useState<FileState[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();

  const appendFiles = (files: File[]) => {
    setErrorMessage("");
    const allFiles = [...selectedFiles, ...files.map(file => ({ file, source: guessFileSource(file) }))];
    const uniqueFiles = uniqBy(allFiles, sf => sf.file.name && sf.file.size);
    setSelectedFiles(uniqueFiles);

    if (uniqueFiles.length !== allFiles.length) {
      setErrorMessage("cannot upload two versions of the same file");
    }
  };

  const setFileSource = (i: number) => (source: string) =>
    setSelectedFiles(selectedFiles.map((f, j) => (j === i ? { ...f, source } : f)));

  const removeFile = (i: number) => setSelectedFiles(selectedFiles.flatMap((f, j) => (j === i ? [] : [f])));

  const uploadValues = () => {
    const [publisherScoreFiles, other] = partition(selectedFiles, f => !!f.source);
    if (other.length > 0) {
      setErrorMessage("must select a source for all files");
      return;
    }
    reportStore.updateRemoteReport({
      name: "upload report value file",
      fn: () =>
        logger.wrapOperation(
          "uploadSources",
          reportApi.uploadSources(report.id, publisherScoreFiles as AssessmentReportSourceFile[]),
          {
            reportId: report.id,
            files: publisherScoreFiles.map(f => ({ name: f.file.name, source: f.source })),
          },
        ),
      sideEffect: onClose,
    });
  };

  return (
    <Modal
      title="Upload Score Report"
      onClose={onClose}
      containerStyle={{ height: "70%", width: "70rem", maxWidth: "70%", minWidth: "360px", backgroundColor: "white" }}
    >
      <div className="h-full w-full p-4 flex flex-col">
        <div className="w-full h-64 shrink-0 max-h-[40%] min-h-[160px]">
          <FileInput handleUpload={appendFiles} allowMultiple />
        </div>
        <div className="w-full h-0 mt-6 flex flex-wrap place-items-start gap-4 grow overflow-y-auto min-h-[80px]">
          <div className="w-full flex flex-wrap">
            {selectedFiles.map(({ file, source }, i) => (
              <div className="w-80 mb-4" key={i}>
                <p className="truncate pr-2">{file.name}</p>
                <div className="flex flex-row gap-4 items-center">
                  <Select
                    label={`file-sources-${i}`}
                    options={FileSourceOptions}
                    value={source}
                    onChange={setFileSource(i)}
                    placeholder="-- Select a file source --"
                  />
                  <TrashIcon onClick={() => removeFile(i)} className="w-4 h-4 cursor-pointer" />
                </div>
              </div>
            ))}
          </div>
        </div>
        {errorMessage && (
          <div className="mt-6 text-red-500 flex items-center gap-2">
            <XCircleIcon onClick={() => setErrorMessage("")} className="h-5 w-5 cursor-pointer" />
            <p>{errorMessage}</p>
          </div>
        )}
        {selectedFiles.length > 0 && (
          <div className="m-auto">
            <Button
              text="Upload"
              icon={<WrenchScrewdriverIcon />}
              onClick={uploadValues}
              className={classNames("mt-8 px-8", DEFAULT_BUTTON_CLASS)}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UploadSourceFilesModal;
