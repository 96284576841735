import { useContext, useEffect, useState } from "react";
import { useMeasure } from "@react-hookz/web";
import { AssessmentImageMargin, AssessmentTestData } from "@parallel/vertex/types/assessment.types";
import { MarginSelect } from "@/components/common/elements/input/MarginSelect";
import config from "@/config";
import { StimulusStoreContext } from "@/store";

export enum ImageType {
  Staff = "Staff",
  Client = "Client",
}

export type ImageMarginProps = {
  test: AssessmentTestData;
  testIndex: number;
  type: ImageType;
};

const fileUrl = (filePath: string) => `${config.nexusServerUrl}/image${filePath}`;

// check deep equality, allowing for some rounding error
const isEqual = (m1: AssessmentImageMargin, m2: AssessmentImageMargin) =>
  Math.abs(m1.left - m2.left) < 2 &&
  Math.abs(m1.top - m2.top) < 2 &&
  Math.abs(m1.right - m2.right) < 2 &&
  Math.abs(m1.bottom - m2.bottom) < 2;

const EditTestImageMargin = ({
  test,
  type,
  selectNext,
  selectPrevious,
}: ImageMarginProps & {
  selectNext?: () => void;
  selectPrevious?: () => void;
}) => {
  const stimulusStore = useContext(StimulusStoreContext);

  const { path, margins: currMargin } =
    type === ImageType.Client
      ? { path: test.image.client, margins: test.image.clientMargin }
      : { path: test.image.staff, margins: test.image.staffMargin };

  const [editMargin, setEditMargin] = useState(currMargin);
  useEffect(() => {
    setEditMargin(currMargin);
  }, [currMargin]);

  const [containerArea, containerRef] = useMeasure<HTMLDivElement>();
  const [naturalImageArea, setNaturalImageArea] = useState<{ width: number; height: number }>();
  useEffect(() => {
    if (!path) return;
    setNaturalImageArea(undefined);
    const image = new Image();
    image.onload = () => setNaturalImageArea({ width: image.naturalWidth, height: image.naturalHeight });
    image.src = fileUrl(path);
  }, [path]);

  if (!path || !editMargin || !naturalImageArea) return <p>Loading ...</p>;

  const containerHeight = containerArea?.height || 0;
  const imageAspectRatio = naturalImageArea.width / naturalImageArea.height;

  const persistMargin = () => {
    const updateKey = type === ImageType.Client ? "clientMargin" : "staffMargin";
    stimulusStore.updateTest({
      id: test.id,
      image: {
        ...test.image,
        [updateKey]: editMargin,
      },
    });
  };

  return (
    <div className="w-full h-full flex flex-row gap-4" ref={containerRef}>
      <div
        style={{
          backgroundImage: `url("${fileUrl(path)}")`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100%",
          width: containerHeight * imageAspectRatio,
        }}
      >
        <MarginSelect selection={editMargin} setSelection={setEditMargin} selectableArea={naturalImageArea} />
      </div>
      <div className="w-0 grow flex flex-col p-2">
        <div className="w-full flex flex-row justify-center">
          <span className="w-16">Left</span>
          <span className="w-16">{editMargin.left}px</span>
        </div>
        <div className="w-full flex flex-row justify-center">
          <span className="w-16">Top</span>
          <span className="w-16">{editMargin.top}px</span>
        </div>
        <div className="w-full flex flex-row justify-center">
          <span className="w-16">Right</span>
          <span className="w-16">{editMargin.right}px</span>
        </div>
        <div className="w-full flex flex-row justify-center">
          <span className="w-16">Bottom</span>
          <span className="w-16">{editMargin.bottom}px</span>
        </div>

        <div className="mt-4">
          {selectPrevious && (
            <button onClick={selectPrevious} className="w-full mt-2 border border-grey-900 rounded-md cursor-pointer">
              Prev
            </button>
          )}
          {selectNext && (
            <button onClick={selectNext} className="w-full mt-2 border border-grey-900 rounded-md cursor-pointer">
              Next
            </button>
          )}
        </div>

        {!isEqual(editMargin, currMargin || ({} as AssessmentImageMargin)) && (
          <>
            <button
              onClick={() => setEditMargin(currMargin)}
              className="w-full mt-4 border border-grey-900 rounded-md cursor-pointer"
            >
              Reset
            </button>
            <button onClick={persistMargin} className="w-full mt-2 border border-grey-900 rounded-md cursor-pointer">
              Submit
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default EditTestImageMargin;
