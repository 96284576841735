import { useContext, useState } from "react";
import { WrenchScrewdriverIcon } from "@heroicons/react/20/solid";
import { ExclamationTriangleIcon, ArrowPathIcon } from "@heroicons/react/24/solid";
import { format } from "fecha";
import { ReportStatus } from "@parallel/vertex/enums/report.enums";
import { ExtendedFormSubmission } from "@parallel/vertex/types/form.types";
import { AssessmentReport } from "@parallel/vertex/types/report.types";
import ConfirmationModal, { ConfirmAction } from "@/components/common/content/ConfirmationModal";
import { LoadingModal } from "@/components/common/content/LoadingModal";
import { Button, ButtonStyleClass, ConfirmButton } from "@/components/common/elements/button/Button";
import { AlertStoreContext, ApiStoreContext, ReportStoreContext, loggerContext } from "@/store";
import { initLogger } from "@/utils";
import { useLoggedRedirect } from "@/utils/redirect.utils";
import { SubHeaderValidationItem } from "../header/SubHeaderControls";
import ConfigWindowLayout from "./ConfigWindowLayout";

const PARAGRAPH_STYLE_CLASSES = "text-lg max-w-[550px] text-justify";
const logger = initLogger("FinalizeStructureConfig", loggerContext);

const FinalizeStructureConfig = ({
  report,
  testingPlanSubmission,
}: {
  report: AssessmentReport;
  testingPlanSubmission?: ExtendedFormSubmission;
}) => {
  const reportStore = useContext(ReportStoreContext);
  const alertStore = useContext(AlertStoreContext);

  const { reportApi } = useContext(ApiStoreContext);
  const { areMeasuresUsedValid, areTestInterpretationsValid } = reportStore;

  let cannotBuildReportMessage = "";
  if (report.formSubmissionIds && !testingPlanSubmission?.formAnswers) {
    cannotBuildReportMessage = "Testing plan must be submitted";
  } else if (!report.formSubmissionIds && !areMeasuresUsedValid) {
    cannotBuildReportMessage = "Must select at least one measure used before building";
  } else if (!areTestInterpretationsValid) {
    cannotBuildReportMessage = "Must select at least one section to include before building";
  }

  const [isResetting, setIsResetting] = useState(false);

  const redirect = useLoggedRedirect(logger);

  const hasReportBeenBuilt = !!report.docId;
  const reportBuildDate = report?.docBuiltAt?.isoString ? new Date(report?.docBuiltAt?.isoString) : undefined;

  const [confirmAction, setConfirmAction] = useState<ConfirmAction | undefined>(undefined);

  let resetButtonTooltipMessage = "";
  if (report.status === ReportStatus.Complete) resetButtonTooltipMessage = "A completed report cannot be reset";

  const softDeleteReport = async () => {
    if (!report.id) return;
    try {
      setIsResetting(true);
      await logger.wrapOperation("deleteReport", reportApi.softDeleteReport(report.id));
      setIsResetting(false);
      redirect(`/assessment/report/new`, "archived report and redirecting to create");
    } catch (e) {
      setIsResetting(false);
      alertStore.setFailedProcess("reset assessment report", e as Error);
    }
  };

  const handleConfirmationActionChange = (updatedConfirmAction: ConfirmAction) => {
    setConfirmAction(updatedConfirmAction);
  };

  if (hasReportBeenBuilt)
    return (
      <ConfigWindowLayout
        title="Report Structure Locked"
        subtitle="The Report structure is locked and ready to populate data"
        isSectionLocked={hasReportBeenBuilt}
      >
        <div className="w-full pt-16 flex justify-center gap-4 bg-white">
          <div className="w-full gap-8 text-center">
            <div className="flex flex-col gap-8 items-center">
              {reportBuildDate && (
                <div className={`flex flex-col ${PARAGRAPH_STYLE_CLASSES}`}>
                  Date Locked: {format(reportBuildDate, "MMMM DD, YYYY")}
                </div>
              )}
              <div className={`flex flex-col ${PARAGRAPH_STYLE_CLASSES}`}>
                The report has already been built, and the Testing Plan and Sections to Include are locked.
              </div>
              <div className={`flex flex-col ${PARAGRAPH_STYLE_CLASSES}`}>
                You can reset the report structure, however once reset, you will be taken back to the initial report
                setup.
              </div>
              <div className={`flex flex-col ${PARAGRAPH_STYLE_CLASSES}`}>
                You will need to select your "Testing Plan" and "Sections to Include" again.
              </div>
              <div>
                <Button
                  text="Reset Structure"
                  styleClass={ButtonStyleClass.Danger}
                  icon={<ArrowPathIcon />}
                  disabled={report.status === ReportStatus.Complete || !!report.deletedAt}
                  onClick={() =>
                    handleConfirmationActionChange({
                      action: () => softDeleteReport(),
                      name: "report-soft-delete",
                      spec: {
                        title: "Reset Report Structure",
                        prompt:
                          "Resetting the report structure will reset all report data, and return you to the create report page. You will need to re-select your Testing Plan and Sections To Include.",
                        submitButtonText: "Reset Report Structure",
                      },
                    })
                  }
                  tooltip={
                    resetButtonTooltipMessage
                      ? {
                          id: "reset-report-button",
                          text: resetButtonTooltipMessage,
                          place: "top" as any,
                        }
                      : undefined
                  }
                />
              </div>
              {isResetting && <LoadingModal />}
            </div>
          </div>
        </div>
        {!!confirmAction && <ConfirmationModal {...confirmAction} onClose={() => setConfirmAction(undefined)} />}
      </ConfigWindowLayout>
    );
  return (
    <ConfigWindowLayout
      title="Finalize Structure"
      subtitle={`Click "Finalize Structure" to lock the structure and build the report`}
      headerControls={
        <div className="flex flex-col sm:invisible md:visible">
          {report.formSubmissionIds ? (
            <SubHeaderValidationItem
              text="Testing plan must be submitted"
              isValid={!!testingPlanSubmission?.formAnswers}
            />
          ) : (
            <SubHeaderValidationItem text="Select at least one measure used" isValid={areMeasuresUsedValid} />
          )}
          <SubHeaderValidationItem
            text="Select at least one section to include"
            isValid={areTestInterpretationsValid}
          />
        </div>
      }
      isSectionLocked={hasReportBeenBuilt}
    >
      <div className="w-full pt-16 flex justify-center gap-4 bg-white">
        <div className="w-full gap-8 text-center">
          <div className="flex flex-col justify-center gap-8 items-center">
            <ExclamationTriangleIcon className="h-20 w-20 shrink-0 fill-failure" />

            <div className={`flex flex-col ${PARAGRAPH_STYLE_CLASSES}`}>
              Finalizing report structure will lock the Testing Plan and Sections to Include you have selected. The
              report structure will be generated in Google Docs, and data can then be uploaded into the report.
            </div>
            <div className={`flex flex-col ${PARAGRAPH_STYLE_CLASSES}`}>
              This will lock the report structure, and you will be unable to edit the Testing Plan and Sections to
              Include you have selected after completion.
            </div>

            <div>
              <ConfirmButton
                text="Finalize Structure"
                icon={cannotBuildReportMessage ? <ExclamationTriangleIcon /> : <WrenchScrewdriverIcon />}
                disabled={!hasReportBeenBuilt ? !!cannotBuildReportMessage : report.status === ReportStatus.Complete}
                onClick={() => reportStore.buildIncrementally()}
                tooltip={
                  cannotBuildReportMessage
                    ? {
                        id: "build-doc-button",
                        text: cannotBuildReportMessage,
                        place: "top" as any,
                      }
                    : undefined
                }
              />
            </div>
          </div>
        </div>
      </div>

      {!!confirmAction && <ConfirmationModal {...confirmAction} onClose={() => setConfirmAction(undefined)} />}
    </ConfigWindowLayout>
  );
};

export default FinalizeStructureConfig;
