import { Eligibility, HydratedEligibility, HydratedNeed, Need } from "@parallel/vertex/types/report.guidance.types";
import { AssessmentReport, ReportEligibility, ReportId } from "@parallel/vertex/types/report.types";
import { AlertStore } from "@/store/alert.store";
import { UserStore } from "@/store/user.store";
import { AuthorizedBaseAPI } from "./base.api";

export class ReportGuidanceAPI extends AuthorizedBaseAPI {
  constructor(alertStore: AlertStore, userStore: UserStore) {
    super(alertStore, userStore);
  }

  public getAllEligibilities = (): Promise<Eligibility[]> =>
    this.instance.get("/report/guidance/eligibility/all").then(r => r.data);

  public getEligibilitiesByIds = (ids: string[]): Promise<HydratedEligibility[]> =>
    this.instance.get(`/report/guidance/eligibility`, { params: { id: ids } }).then(r => r.data);

  public upsertEligibility = (
    reportId: ReportId,
    eligibilityId: string,
    update?: Partial<ReportEligibility>,
  ): Promise<{ report: AssessmentReport; eligibility: HydratedEligibility }> =>
    this.instance.put(`/report/guidance/${reportId}/eligibility/${eligibilityId}`, update).then(r => r.data);

  public deleteEligibility = (reportId: ReportId, eligibilityId: string): Promise<AssessmentReport> =>
    this.instance.delete(`/report/guidance/${reportId}/eligibility/${eligibilityId}`).then(r => r.data);

  public getAllNeeds = (): Promise<Need[]> => this.instance.get("/report/guidance/need/all").then(r => r.data);

  public getNeedsByIds = (ids: string[]): Promise<HydratedNeed[]> =>
    this.instance.get(`/report/guidance/need`, { params: { id: ids } }).then(r => r.data);

  public upsertReportNeeds = (reportId: ReportId, needIds: string[]): Promise<AssessmentReport> =>
    this.instance.put(`/report/guidance/${reportId}/need`, { needIds }).then(r => r.data);

  public removeReportNeeds = (reportId: ReportId, needIds: string[]): Promise<AssessmentReport> =>
    this.instance
      .delete(`/report/guidance/${reportId}/need`, {
        data: {
          needIds,
        },
      })
      .then(r => r.data);

  public upsertRecommendationIds = (
    reportId: ReportId,
    needIds: string[],
    recommendationIds: string[],
  ): Promise<AssessmentReport> =>
    this.instance.put(`/report/guidance/${reportId}/recommendation`, { needIds, recommendationIds }).then(r => r.data);
}
