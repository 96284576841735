import { CheckCircleIcon as CheckCircleOutlineIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon as CheckCircleSolidIcon } from "@heroicons/react/24/solid";
import { classNames } from "@/utils";
import Select from "../elements/select/Select";

export type ToggleItem = {
  key: string;
  name: string;
  isEnabled?: boolean;
  optionValue?: string;
};

export const iconClassName = "h-5 w-5 mr-4";

export const CheckboxCircleIcon = ({ isEnabled, canUpdate }: { isEnabled?: boolean; canUpdate?: boolean }) => (
  <div className="w-8 h-8 shrink-0 rounded-md grid place-items-center">
    {isEnabled ? (
      <CheckCircleSolidIcon className={classNames("w-6 h-6", canUpdate ? "text-ocean" : "text-slate-400")} />
    ) : (
      canUpdate && <CheckCircleOutlineIcon className="w-6 h-6 text-slate-400" />
    )}
  </div>
);

type ItemOptions = {
  options: string[];
  onChange: (itemId: string, value: string) => void;
};

export const SelectItem = ({
  item: { key, name, isEnabled, optionValue },
  onClick,
  itemOptions,
  containerClassName,
  canUpdate,
}: {
  item: ToggleItem;
  onClick?: (key: string) => void;
  itemOptions?: ItemOptions;
  containerClassName?: string;
  canUpdate?: boolean;
}) => (
  <div className={classNames("flex flex-row place-items-center", isEnabled ? "" : "bg-gray-100")}>
    <div
      onClick={e => {
        if (!onClick) return;
        e.stopPropagation();
        onClick(key);
      }}
      className={classNames(
        !!onClick && !!canUpdate ? "hover:text-ocean cursor-pointer" : "cursor-default",
        "grow py-4 px-4 flex flex-row gap-5 font-semibold place-items-center",
        containerClassName,
      )}
      key={key}
    >
      <CheckboxCircleIcon canUpdate={canUpdate} isEnabled={isEnabled} />

      <span className="grow">{name}</span>
    </div>
    {itemOptions && isEnabled && (
      <div className="w-44 px-4 shrink-0">
        <Select
          label={`${key}-select`}
          options={itemOptions.options.map(o => ({ name: o, value: o }))}
          value={optionValue}
          onChange={value => itemOptions.onChange(key, value)}
        />
      </div>
    )}
  </div>
);

const MultiSelectWindow = ({
  items,
  setEnabledItems,
  itemOptions,
  isSectionLocked,
}: {
  items: ToggleItem[];
  setEnabledItems?: (keys: string[]) => void;
  itemOptions?: ItemOptions;
  isSectionLocked?: boolean;
}) => {
  const toggleItem =
    setEnabledItems &&
    ((toggleKey: string) => {
      const enabledKeys = items
        .filter(({ key, isEnabled }) => (key === toggleKey ? !isEnabled : isEnabled))
        .map(s => s.key);
      setEnabledItems(enabledKeys);
    });
  return (
    <div className="divide-y">
      {items.map(item => (
        <SelectItem
          item={item}
          onClick={toggleItem && (key => toggleItem(key))}
          itemOptions={itemOptions}
          key={item.key}
          canUpdate={!isSectionLocked}
        />
      ))}
    </div>
  );
};

export default MultiSelectWindow;
