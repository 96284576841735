import { useContext } from "react";
import { observer } from "mobx-react-lite";
import { ExtendedStudentUser } from "@parallel/vertex/types/user/student.types";
import AutoCompleteSelect from "@/components/common/elements/input/auto-complete/AutoCompleteSelect";
import { ApiStoreContext } from "@/store";

const CurrentUserClientSelect = ({
  userSelected,
  onShowToggle = () => null,
  focusOnMount,
  containerClasses,
  inputClasses,
}: {
  userSelected: (user: ExtendedStudentUser) => void;
  onShowToggle?: (isShowing: boolean) => void;
  focusOnMount?: boolean;
  containerClasses?: string;
  inputClasses?: string;
  isWarning?: (user: ExtendedStudentUser) => boolean;
}) => {
  const { userApi } = useContext(ApiStoreContext);
  return (
    <AutoCompleteSelect
      fetchItems={(keyword, signal) => userApi.searchStudents({ keyword }, signal).then(r => r.records)}
      itemSelected={userSelected}
      displayItem={user => ({
        name: user.fullName,
        key: user.userId,
      })}
      onShowToggle={onShowToggle}
      focusOnMount={focusOnMount}
      containerClassName={containerClasses ? containerClasses : "py-3 border-slate-300 border"}
      inputClassName={inputClasses ? inputClasses : "border-b"}
    />
  );
};

export default observer(CurrentUserClientSelect);
