import { GridColDef } from "@mui/x-data-grid";
import PaginatedTable from "./PaginatedTable";

/**
 *
 * Client-Side Paginated Data Table
 * Pass in the rows and columns, and all pagination
 * is handled by the x-data-grid package
 *
 * @param onRowSelect Callback function for checkbox selection that takes in a list of ids: `ids: (string | number)[]`
 * @param rows: all table rows must be passed in as a prop for client side pagination
 * @param columns: table column definitions (see `GridColDef[]`)
 */
export const ClientPaginatedTable = ({
  gridStyles,
  rows,
  rowHeight,
  columns,
  onRowSelect,
  onRowClick,
  loadingRows,
  disableColumnFilters,
  enableFuzzySearch,
  hideToolbar,
  pageSize,
  checkboxSelection = true,
  hideFooter = false,
}: {
  gridStyles?: object;
  onRowSelect?: (ids: (string | number)[]) => any;
  onRowClick?: (params: any) => any;
  rows: any[];
  rowHeight?: number;
  columns: GridColDef[];
  loadingRows?: boolean;
  disableColumnFilters?: boolean;
  enableFuzzySearch?: boolean;
  checkboxSelection?: boolean;
  pageSize?: number;
  hideToolbar?: boolean;
  hideFooter?: boolean;
}) => {
  return (
    <PaginatedTable
      gridStyles={gridStyles}
      rows={rows}
      rowHeight={rowHeight}
      columns={columns}
      loading={loadingRows}
      pageSizeOptions={pageSize ? [pageSize] : [10, 20, 50]}
      disableColumnFilters={disableColumnFilters}
      onRowSelect={ids => onRowSelect && onRowSelect(ids)}
      onRowClick={(params: any) => onRowClick && onRowClick(params.row)}
      slotProps={{
        toolbar: hideToolbar
          ? undefined
          : {
              showQuickFilter: enableFuzzySearch,
              printOptions: {
                disableToolbarButton: true,
              },
            },
      }}
      pageSize={pageSize ? pageSize : undefined}
      hideToolbar={hideToolbar}
      hideFooter={hideFooter}
      filterMode={"client"}
      paginationMode={"client"}
      checkboxSelection={checkboxSelection}
    />
  );
};
export default ClientPaginatedTable;
