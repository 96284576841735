import { ExtendedFormSubmission } from "@parallel/vertex/types/form.types";
import { AuthorizedBaseAPI } from "@/api/base.api";
import { AlertStore } from "@/store/alert.store";
import { UserStore } from "@/store/user.store";

export class FormAPI extends AuthorizedBaseAPI {
  constructor(alertStore: AlertStore, userStore: UserStore) {
    super(alertStore, userStore);
  }

  public getSubmission = (submissionId: string): Promise<ExtendedFormSubmission> =>
    this.instance.get(`/form/submission/${submissionId}`).then(r => r.data);

  public updateSubmission = (submissionId: string, formAnswers: Record<string, any>): Promise<ExtendedFormSubmission> =>
    this.instance.put(`form/submission/${submissionId}`, { formAnswers }).then(r => r.data);
}
