import { useContext } from "react";
import { reportInfoSources } from "@parallel/vertex/static-data";
import { AssessmentReport } from "@parallel/vertex/types/report.types";
import MultiSelectWindow from "@/components/common/windows/MultiSelectWindow";
import { ApiStoreContext, ReportStoreContext } from "@/store";
import { SubHeaderValidationItem } from "../header/SubHeaderControls";
import ConfigWindowLayout from "./ConfigWindowLayout";

const MeasuresUsedConfig = ({ report }: { report: AssessmentReport }) => {
  const { reportApi } = useContext(ApiStoreContext);
  const reportStore = useContext(ReportStoreContext);
  const { areMeasuresUsedValid } = reportStore;

  const hasReportBeenBuilt = !!report.docId;
  const infoSourceItems = reportInfoSources(report.type).map(s => ({
    ...s,
    isEnabled: report.infoSourceKeys?.includes(s.key),
  }));

  const updateMeasuresUsed = (infoSourceKeys: string[]) => {
    if (hasReportBeenBuilt) return;
    return reportStore.updateRemoteReport({
      name: "update measures used",
      fn: () => reportApi.updateReport(report.id, { infoSourceKeys }),
      updateLocalConfig: true,
    });
  };

  return (
    <ConfigWindowLayout
      title="Measures Used"
      subtitle="These selections will reflect which measures were used during assessment"
      headerControls={<SubHeaderValidationItem text="Select at least one measure" isValid={areMeasuresUsedValid} />}
      isSectionLocked={hasReportBeenBuilt}
    >
      <div className="w-full flex flex-col gap-4 bg-white">
        <MultiSelectWindow
          items={infoSourceItems}
          setEnabledItems={updateMeasuresUsed}
          isSectionLocked={hasReportBeenBuilt}
        />
      </div>
    </ConfigWindowLayout>
  );
};

export default MeasuresUsedConfig;
