import { useLocation, useNavigate } from "react-router-dom";
import { forEach, isEmpty } from "lodash";
import { ClientLogger } from "./logging.utils";

type RedirectOptions = { queryParams?: Record<string, string>; clearParams?: boolean };

export type RedirectFn = (toUrl: string, reason: string, options?: RedirectOptions) => void;

export const useLogFnRedirect = (logFn: (msg: string, context: any) => void): RedirectFn => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (toUrl, reason, { queryParams = {}, clearParams } = {}) => {
    let url = toUrl;
    if (!clearParams && (!!search || !isEmpty(queryParams))) {
      const params = new URLSearchParams(search);
      forEach(queryParams, (value, key) => params.set(key, value));
      url += `?${params}`;
    }
    logFn(`redirecting to ${toUrl}`, { toUrl, reason, queryParams, currUrl: window.location.href });
    navigate(url);
  };
};

export const useLoggedRedirect = (logger: ClientLogger): RedirectFn => useLogFnRedirect(logger.info);
