import { useContext } from "react";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { formatNounWithCount } from "@parallel/vertex/util/string.util";
import Modal from "@/components/common/content/Modal";
import { ReportStoreContext } from "@/store";
import { ReportBuildStatus } from "@/store/report.store";
import { toHeaderString } from "@/utils/assessment";

const BuildReportModal = () => {
  const reportStore = useContext(ReportStoreContext);
  const { buildResult, reportBuildStatus } = reportStore;

  if (!buildResult || reportBuildStatus !== ReportBuildStatus.Finished) return <></>;

  const { success: interviewSuccess, failures: interviewFailures } = buildResult.interviewForms;
  const interviewSuccessText = `${formatNounWithCount(interviewSuccess.length, "interview note form")} injected`;
  const interviewErrorText =
    interviewFailures.length > 0 &&
    `${formatNounWithCount(interviewFailures.length, "error")} (${interviewFailures.map(f => toHeaderString(f.attempt)).join(", ")})`;

  const { success: valueSuccess, failures: valueFailures } = buildResult.values;
  const valueSuccessText = `${formatNounWithCount(valueSuccess.length, "data group")} injected`;
  const valueErrorText =
    valueFailures.length > 0 &&
    `${formatNounWithCount(valueFailures.length, "error")} (${valueFailures.map(f => toHeaderString(f.attempt)).join(", ")})`;

  const { success: contentSuccess, failures: contentFailures } = buildResult.content;
  const contentSuccessText = `${formatNounWithCount(contentSuccess.length, "doc section")} updated`;
  const contentErrorText =
    contentFailures.length > 0 &&
    `${formatNounWithCount(contentFailures.length, "error")} (${contentFailures.map(f => toHeaderString(f.attempt)).join(", ")})`;

  return (
    <Modal
      title="Build Finished"
      onClose={action(() => (reportStore.reportBuildStatus = ReportBuildStatus.NotRunning))}
      containerStyle={{ minWidth: "33%", maxWidth: "50%" }}
    >
      <div className="py-2 text-lg">
        <p>{interviewSuccessText}</p>
        {interviewErrorText && <p>{interviewErrorText}</p>}
      </div>
      <div className="py-2 text-lg">
        <p>{valueSuccessText}</p>
        {valueErrorText && <p>{valueErrorText}</p>}
      </div>
      <div className="py-2 text-lg">
        <p>{contentSuccessText}</p>
        {contentErrorText && <p>{contentErrorText}</p>}
      </div>
    </Modal>
  );
};

export default observer(BuildReportModal);
